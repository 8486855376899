<div class="" (mouseleave)="onMouseLeave()" (mouseenter)="onMouseEnter()">

  <button
    type="button"
    #dropdownButton
    class="w-full z-20"
    [ngClass]="{'p-2.5 text-xs 2xl:text-sm text-primary font-semibold rounded-lg rounded-gray-100 rounded-2 border border-gray-300 focus:border-2 focus:ring-gray15 focus:border-gray15 flex justify-between items-end': !hasRawContent}"
    (click)="toggleDropdown()"
    [class.border-red-400]="hasError"
  >
    <ng-container *ngIf="hasRawContent">
      <ng-content select="[rawButton]"></ng-content>
    </ng-container>
    <ng-container *ngIf="!hasRawContent">
      <div>{{ buttonText }}</div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.7083 15.7071C12.5208 15.8946 12.2665 15.9999 12.0013 15.9999C11.7362 15.9999 11.4818 15.8946 11.2943 15.7071L5.63732 10.0501C5.54181 9.95785 5.46563 9.84751 5.41322 9.7255C5.36081 9.6035 5.33322 9.47228 5.33207 9.3395C5.33092 9.20672 5.35622 9.07504 5.4065 8.95215C5.45678 8.82925 5.53103 8.7176 5.62492 8.6237C5.71882 8.52981 5.83047 8.45556 5.95337 8.40528C6.07626 8.355 6.20794 8.32969 6.34072 8.33085C6.4735 8.332 6.60472 8.35959 6.72672 8.412C6.84873 8.46441 6.95907 8.54059 7.05132 8.6361L12.0013 13.5861L16.9513 8.6361C17.1399 8.45394 17.3925 8.35315 17.6547 8.35542C17.9169 8.3577 18.1677 8.46287 18.3531 8.64828C18.5385 8.83369 18.6437 9.0845 18.646 9.3467C18.6483 9.60889 18.5475 9.8615 18.3653 10.0501L12.7083 15.7071Z"
          fill="black"
        />
      </svg>
    </ng-container>
  </button>
  <div
    #dropdownList
    [ngStyle]="{ 'min-width': contentWidth + 'px' }"
    class="z-[999999] shadow-2xl opacity-0"
    [ngClass]="{ 'invisible opacity-0': !showDropdown, 'transition-all duration-300 ': initialized , 'opacity-100': showDropdown, 'p-2 bg-white': !hasRawContent}"
  >
    <div>
      <ul>
        <ng-content select="[header]"></ng-content>
      </ul>
      <ul class="max-h-[300px] overflow-y-auto" (click)="toggleDropdown()">
        <ng-content></ng-content>
      </ul>
    </div>
  </div>
</div>
