import { Component, OnDestroy } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { RequestSection } from '../../backend-services/request.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FileDeleteConfirmationModalService } from '../file-delete-confirmation-modal/file-delete-confirmation-modal.service';

@Component({
  selector: 'app-uploaded-file-preview-section',
  templateUrl: './uploaded-file-preview-section.component.html',
  styleUrls: ['./uploaded-file-preview-section.component.scss'],
})
export class UploadedFilePreviewSectionComponent implements OnDestroy {
  changeListener?: any;
  cachedSelectedSection?: RequestSection;

  constructor(
    public requestViewPageService: RequestViewPageService,
    public sanitizer: DomSanitizer,
    private fileDeleteConfirmationModalService: FileDeleteConfirmationModalService
  ) {
    // listen for changes in upload files
    this.changeListener = setInterval(() => {
      if (
        this.requestViewPageService.selectedSection &&
        this.requestViewPageService.selectedSection !==
          this.cachedSelectedSection
      ) {
        this.cachedSelectedSection =
          this.requestViewPageService.selectedSection;
        this.initCarousel();
      }
    }, 50);
  }

  get selectedUploadedFile() {
    let fileIndex = this.requestViewPageService.selectedUploadFileIndex;
    return this.cachedSelectedSection?.uploaded_files[fileIndex];
  }

  ngOnDestroy() {
    if (this.changeListener) {
      clearInterval(this.changeListener);
    }
  }

  initCarousel() {
    setTimeout(() => {
      const $ = (window as any).$;
      $('.slick-carousel').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: 'ondemand',
        prevArrow: '.slick-prev-arrow',
        nextArrow: '.slick-next-arrow',
      });

      const that = this;

      $('.slick-carousel').on('afterChange', function () {
        const dataId = $('.slick-current').attr('data-slick-index');
        that.requestViewPageService.selectedUploadFileIndex = parseInt(dataId);
      });
    }, 100);
  }

  removeFile() {
    this.fileDeleteConfirmationModalService.modalEvents.next({
      type: 'open',
      data: {
        section_id: this.requestViewPageService.selectedSection!.id,
        uploaded_file_id: this.selectedUploadedFile!.id,
        request_hash: this.requestViewPageService.requestPublicHash,
      },
    });
  }

  get showNavigator() {
    return (
      this.cachedSelectedSection &&
      this.cachedSelectedSection?.uploaded_files?.length > 1
    );
  }

  get canRemoveFile() {
    return (
      this.cachedSelectedSection?.status === 1 ||
      this.cachedSelectedSection?.status === 101 ||
      this.cachedSelectedSection?.status === 102 ||
      this.cachedSelectedSection?.status === 202 ||
      this.cachedSelectedSection?.status === 302
    );
  }

  get fileCount() {
    return this.cachedSelectedSection?.uploaded_files?.length;
  }
}
