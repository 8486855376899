<div class="mb-10 font-Satoshi">
  <div class="grid grid-cols-4 cursor-pointer" (click)="toggleExpanded()">
    <div class="col-span-3 flex gap-2 items-center">
      <img
        class="w-8 h-8 duration-300"
        [ngClass]="{ 'rotate-90': isExpanded }"
        src="assets/imgs/icons/bs-chevron-right.svg"
        alt="right arrow"
      />
      <h2
        class="font-bold text-xl leading-7 text-primary text-wrap break-words max-w-[70%]"
      >
        {{ sectionGroup.title }}
      </h2>
      <div class="ml-2 bg-white rounded-lg px-2 py-1 text-xs">
        {{ sectionGroup.sections.length }}
      </div>
    </div>
    <div class="w-full flex justify-end items-center">
      <app-status-display
        [variant]="4"
        [statusId]="
          requestViewPageService.selectedSectionGroupStatusId(sectionGroup)
        "
      ></app-status-display>
    </div>
  </div>
  <div class="expander" [ngClass]="{ expanded: isExpanded }">
    <div class="expander-content">
      <div
        class="flex justify-between px-6 py-8 my-3 border-2 rounded-2xl cursor-pointer"
        [ngClass]="{
          'border-light-blue5':
            requestViewPageService.selectedSection?.id === section.id
        }"
        *ngFor="let section of sectionGroup.sections"
        (click)="requestViewPageService.setSelectedSection(section)"
      >
        <div class="flex flex-col">
          <h3 class="font-medium text-xl text-primary leading-7">
            {{ section.title }}
          </h3>
          <div
            class="font-normal text-primary opacity-70 text-sm leading-22.4px"
          >
            <ng-container *ngIf="section.dimensions">
              <span>{{ section.dimensions }}</span>
              <span> | </span>
            </ng-container>
            <span>{{ section.allowed_file_formats }}</span>
          </div>
          <app-status-display
            [variant]="1"
            [statusId]="requestViewPageService.selectedSectionStatusId(section)"
          ></app-status-display>
        </div>
        <div *ngIf="requestViewPageService.getSectionThumbnail(section)">
          <img
            class="w-24 h-20 object-fill border rounded-xl"
            [src]="requestViewPageService.getSectionThumbnail(section)"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
