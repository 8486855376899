import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { EditRequestModalService } from '../edit-request-modal/edit-request-modal.service';
import {
  RequestFolder,
  RequestFolderService,
} from '../../backend-services/request-folder.service';
import { Client, ClientService } from '../../backend-services/client.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { SectionCollectionEditModalService } from './section-collection-edit-modal/section-collection-edit-modal.service';
import { SendRequestModalService } from '../send-request-modal/send-request-modal.service';

@Component({
  selector: 'app-edit-request-page',
  templateUrl: './edit-request-page.component.html',
  styleUrls: ['./edit-request-page.component.scss'],
})
export class EditRequestPageComponent implements OnInit {
  @ViewChild('toggleUploadInfoPdfModalButton')
  toggleUploadInfoPdfModalButton!: ElementRef;
  isEditPage = false;

  requestFolderList: RequestFolder[] = [];

  fromRequestFolderId?: number = undefined;
  fromRequestFolderData?: RequestFolder = undefined;

  clientFilterText = new FormControl('');
  filteredAvailableClients: Client[] = [];

  constructor(
    public editRequestModalService: EditRequestModalService,
    public requestFolderService: RequestFolderService,
    public clientService: ClientService,
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private translocoService: TranslocoService,
    public sectionCollectionEditModalService: SectionCollectionEditModalService,
    private sendRequestModalService: SendRequestModalService
  ) {
    this.requestFolderService.getRequestFolders().subscribe({
      next: (res) => {
        this.requestFolderList = res.data;
      },
      error: console.error,
    });

    this.editRequestModalService.modalEvents.subscribe((e) => {
      if (e.type === 'updated') {
        this.requestFolderService.clearCache();
        if (this.isEditPage) {
          this.handleInit();
        } else {
          this.router.navigate([`/requests/edit/${e.request_id}`]);
        }
      }
    });

    this.clientService.availableClientsEvents.subscribe((ev) => {
      if (ev.type === 'updated') {
        this.filterAvailableClients(this.clientFilterText.value);
      }
    });

    this.clientFilterText.valueChanges
      .pipe(debounceTime(300))
      .subscribe((v: any) => this.filterAvailableClients(v));
  }

  ngOnInit(): void {
    this.handleInit();
  }

  handleInit() {
    const requestId = this.activatedRoute.snapshot.paramMap.get('id');
    const client_id =
      this.activatedRoute.snapshot.queryParamMap.get('client_id');
    const request_folder_id = this.activatedRoute.snapshot.queryParamMap.get(
      'request_folder_id'
    )
      ? parseInt(
          this.activatedRoute.snapshot.queryParamMap.get('request_folder_id')!
        )
      : null;
    const duplicate_with_id =
      this.activatedRoute.snapshot.queryParamMap.get('duplicate_with');

    if (request_folder_id) {
      this.fromRequestFolderId = request_folder_id;
      this.requestFolderService.getFolderById(request_folder_id).subscribe({
        next: (res) => {
          this.fromRequestFolderData = res.data;
        },
        error: console.error,
      });
    } else {
      this.fromRequestFolderId = undefined;
      this.fromRequestFolderData = undefined;
    }

    if (requestId) {
      this.isEditPage = true;
      this.editRequestModalService.modalEvents.next({
        type: 'edit',
        id: parseInt(requestId),
      });
    } else {
      this.isEditPage = false;
      this.editRequestModalService.modalEvents.next({
        type: 'new',
        client_id: client_id ? parseInt(client_id) : undefined,
        request_folder_id: request_folder_id ? request_folder_id : undefined,
        duplicate_with_id: duplicate_with_id
          ? parseInt(duplicate_with_id)
          : undefined,
      });
      this.filterAvailableClients(undefined);
    }
  }

  goBack() {
    this.location.back();
  }

  changeRequestFolderValue(newValue: number) {
    this.editRequestModalService.requestFolderControl.setValue(newValue);
  }

  filterAvailableClients(value: any) {
    if (value) {
      this.filteredAvailableClients =
        this.clientService.availableClients.filter((c) =>
          c.company_name.toLowerCase().includes(value.toString().toLowerCase())
        );
    } else {
      this.filteredAvailableClients = this.clientService.availableClients;
    }
  }

  get selectClientText() {
    return this.translocoService.translate(`Select Client`);
  }

  get selectedContactText() {
    const selectedContact = this.clientService
      .getLocalClientById(
        this.editRequestModalService.requestFormGroup.controls.client_id.value
      )
      ?.contacts?.find(
        (c) => c.id === this.editRequestModalService.contactIdControl.value
      );

    if (!selectedContact) {
      return this.translocoService.translate(`Select Contact`);
    }

    return `${selectedContact.first_name} ${selectedContact.last_name} - ${selectedContact.email}`;
  }

  shareRequest() {
    this.sendRequestModalService.modalEvents.next({
      type: 'init',
      request: this.editRequestModalService.currentRequest!,
    });
  }
}
