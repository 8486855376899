import { Component, ElementRef, ViewChild } from '@angular/core';
import { CreateFolderModalService } from './create-folder-modal.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { RequestFolderService } from '../../backend-services/request-folder.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-create-folder-modal',
  templateUrl: './create-folder-modal.component.html',
  styleUrls: ['./create-folder-modal.component.scss'],
})
export class CreateFolderModalComponent {
  allCustomers: Customer[] = [];
  currentFolderId?: number = undefined;

  constructor(
    public createFolderModalService: CreateFolderModalService,
    private customerService: CustomerService,
    private authService: AuthService,
    public requestFolderService: RequestFolderService,
    private toastr: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.requestFolderService.getRequestFolders();

    this.authService.isSuperAdmin.subscribe((isSuperAdmin) => {
      if (isSuperAdmin) {
        // fetch customers if user is SuperAdmin
        this.customerService.getCustomers({_listing: "full"}).subscribe({
          next: (res) => {
            if (res.success) {
              this.allCustomers = res.data;
            }
          },
          error: (err) => console.log(err),
        });
      }
    });
  }

  closeModal() {
    this.createFolderModalService.modalEvents.next({ type: 'close' });
  }

  changeParentFolderValue(newValue?: number) {
    this.createFolderModalService.parentIdControl.setValue(newValue);
  }

  initDeleteFolder() {
    this.createFolderModalService.deleteConfirmModalEvents.next({
      type: 'open',
    });
  }

  handleFolderDelete() {
    const currentFolderId = parseInt(
      this.createFolderModalService.createFolderForm.get('id')!.value
    );
    if (this.createFolderModalService) {
      this.requestFolderService.deleteRequestFolder(currentFolderId).subscribe({
        next: () => {
          this.toastr.info(this.translocoService.translate('Folder deleted'));
          this.closeDeleteConfirmModal();
          this.createFolderModalService.modalEvents.next({ type: 'close' });
          this.createFolderModalService.modalEvents.next({ type: 'deleted' });
        },
        error: (res) => {
          console.log(res);
          this.toastr.error(
            res.error?.message
              ? this.translocoService.translate(res.error?.message)
              : res.error?.error
              ? this.translocoService.translate(res.error.error)
              : this.translocoService.translate(`Something went wrong`)
          );
        },
      });
    }
  }

  get selectedCustomer() {
    const customerId = this.createFolderModalService.customerIdControl.value;
    return this.allCustomers.find((c) => c.id === customerId);
  }

  changeCustomerId(id: number) {
    this.createFolderModalService.customerIdControl.setValue(id);
  }

  closeDeleteConfirmModal() {
    this.createFolderModalService.deleteConfirmModalEvents.next({
      type: 'close',
    });
  }
}
