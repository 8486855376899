<div
  class="min-h-screen w-full bg-primaryV2 flex justify-center"
  *transloco="let t"
>
  <div class="py-24 flex flex-col items-center w-full">
    <img src="/assets/imgs/logo_lg.svg" alt="logo" />
    <div
      class="mt-12 w-full max-w-screen md:max-w-[75%] lg:max-w-[600px] mx-auto bg-white rounded-lg overflow-hidden"
    >
      <div class="bg-light-blue2 text-white w-full px-4 md:px-10 pt-5 pb-4">
        <h1 class="text-xl font-bold" transloco="Passwort vergessen">
          Passwort vergessen
        </h1>
      </div>
      <div class="bg-white px-4 md:px-10 pt-8 pb-[38px] gap-6 flex flex-col">
        <app-form-input
          [label]="t('E-Mail')"
          [placeholder]="t('E-Mailadresse eingeben') + '...'"
          [fc]="emailControl"
          [hasError]="emailControl.invalid && emailControl.touched"
          [errorMessage]="t('Ungültige E-Mail')"
        ></app-form-input>
        <div
          class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 font-medium font-Satoshi"
          role="alert"
          *ngIf="serverErrorMessage"
        >
          {{ translocoService.translate(serverErrorMessage) }}
        </div>
        <div
          *ngIf="!success"
          class="text-sm font-Satoshi font-medium"
          transloco="Sie erhalten eine E-Mail mit Informationen zum Zurücksetzen Ihres Passwortes."
        >
          Sie erhalten eine E-Mail mit Informationen zum Zurücksetzen Ihres
          Passwortes.
        </div>
        <div
          class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 font-Satoshi font-medium"
          role="alert"
          *ngIf="success"
          transloco="Wir haben Ihnen den Link zum Zurücksetzen Ihres Passworts per E-Mail zugesandt"
        >
          Wir haben Ihnen den Link zum Zurücksetzen Ihres Passworts per E-Mail
          zugesandt
        </div>
      </div>
      <div class="bg-gray10 px-4 md:px-10 flex justify-between py-[25px]">
        <a
          transloco="Abbrechen"
          class="tw-btn-ghost bg-white"
          routerLink="/login"
        >
          Abbrechen
        </a>
        <button
          transloco="Passwort zurücksetzen"
          class="tw-btn-secondary"
          [disabled]="formIsSubmitting"
          (click)="onSubmit()"
        >
          Passwort zurücksetzen
        </button>
      </div>
    </div>
  </div>
</div>
