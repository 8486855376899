import { Component, OnInit } from '@angular/core';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { InfoUploadModalService } from '../info-upload-modal/info-upload-modal.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import {
  FileType,
  FileTypeService,
} from '../../../backend-services/file-type.service';
import { debounceTime } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-selected-section-view',
  templateUrl: './selected-section-view.component.html',
  styleUrls: ['./selected-section-view.component.scss'],
})
export class SelectedSectionViewComponent {
  showCustomFileTypeForm = false;
  customExtensionControl = new FormControl('');
  fileTypeFilterText = new FormControl('');
  debouncedFilterText?: string = '';

  constructor(
    public editRequestModalService: EditRequestModalService,
    private infoUploadModalService: InfoUploadModalService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService,
    public fileTypeService: FileTypeService,
    private translocoService: TranslocoService
  ) {
    this.fileTypeFilterText.valueChanges
      .pipe(debounceTime(300))
      .subscribe((v: any) => (this.debouncedFilterText = v));
  }

  get selectedSectionGroup() {
    return this.editRequestModalService.sectionCollectionsFormArr.at(
      this.editRequestModalService.selectedCollectionIndex
    ) as FormGroup;
  }

  get selectedSection() {
    return (this.selectedSectionGroup.get('sections') as FormArray).at(
      this.editRequestModalService.selectedSectionIndex
    ) as FormGroup;
  }

  get generatedValidations() {
    return this.selectedSection.get(
      'gen_expected_file_validations'
    ) as FormGroup;
  }

  get currentInfoFiles() {
    return this.selectedSection.get('info_files') as FormArray;
  }

  get canEnforceDimensions() {
    const expectedFiles = (
      this.selectedSection.get('expected_files') as FormArray
    ).controls;

    if (!expectedFiles.length) {
      return false;
    }

    for (let expectedFile of expectedFiles) {
      const fileType = this.fileTypeService.getLocalFileTypeById(
        expectedFile.value.file_type_id
      );
      if (!fileType || !fileType.can_enforce_dimensions) {
        return false;
      }
    }

    return true;
  }

  get isValidatingDimensions() {
    return (
      this.canEnforceDimensions &&
      this.generatedValidations.get('validate_dimensions')?.value
    );
  }

  get canEnforceResolution() {
    const expectedFiles = (
      this.selectedSection.get('expected_files') as FormArray
    ).controls;

    if (!expectedFiles.length) {
      return false;
    }

    for (let expectedFile of expectedFiles) {
      const fileType = this.fileTypeService.getLocalFileTypeById(
        expectedFile.value.file_type_id
      );
      if (!fileType || !fileType.can_enforce_resolution) {
        return false;
      }
    }

    return true;
  }

  get canEnforceProof() {
    const expectedFiles = (
      this.selectedSection.get('expected_files') as FormArray
    ).controls;

    if (!expectedFiles.length) {
      return false;
    }

    for (let expectedFile of expectedFiles) {
      const fileType = this.fileTypeService.getLocalFileTypeById(
        expectedFile.value.file_type_id
      );
      if (!fileType || !fileType.can_request_proof) {
        return false;
      }
    }

    return true;
  }

  get isValidatingResolution() {
    return (
      this.canEnforceResolution &&
      this.generatedValidations.get('validate_resolution')?.value
    );
  }

  get canEnforceColorSpace() {
    const expectedFiles = (
      this.selectedSection.get('expected_files') as FormArray
    ).controls;

    if (!expectedFiles.length) {
      return false;
    }

    for (let expectedFile of expectedFiles) {
      const fileType = this.fileTypeService.getLocalFileTypeById(
        expectedFile.value.file_type_id
      );
      if (!fileType || !fileType.can_enforce_color_space) {
        return false;
      }
    }

    return true;
  }

  get isEnforcingColorspace() {
    return (
      this.canEnforceResolution &&
      this.generatedValidations.get('validate_color_space')?.value
    );
  }

  get enforceColorSpaceControl() {
    return this.generatedValidations.get('color_space') as FormControl;
  }

  toggleUploadInfoPDFModal() {
    this.infoUploadModalService.modalEvents.next({
      type: 'open',
      sectionIndex: this.editRequestModalService.selectedSectionIndex,
      sectionCollectionIndex:
        this.editRequestModalService.selectedCollectionIndex,
    });
  }

  public canAddInfoFiles() {
    return (this.selectedSection.controls.info_files as FormArray).length < 4;
  }

  public removeInfoFile(infoFileIndex: number) {
    (this.selectedSection.controls.info_files as FormArray).removeAt(
      infoFileIndex
    );
  }

  initAddCustomFileType() {
    this.showCustomFileTypeForm = true;
  }

  selectFileGroup(fileList: any[]) {
    this.resetCustomFileTypeForm();
    (this.selectedSection.controls.expected_files as FormArray).clear();

    fileList.map((value) =>
      (this.selectedSection.controls.expected_files as FormArray).insert(
        0,
        this.editRequestModalService.buildExpectedFileFormGroup(
          undefined,
          parseInt(value.id),
          undefined,
          false
        )
      )
    );
  }

  resetCustomFileTypeForm() {
    this.showCustomFileTypeForm = false;
    this.customExtensionControl.setValue('');
  }

  addCustomFileType() {
    const customExtension = this.customExtensionControl.value;
    if (customExtension) {
      (this.selectedSection.get('expected_files') as FormArray).insert(
        0,
        this.editRequestModalService.buildExpectedFileFormGroup(
          undefined,
          undefined,
          { is_custom_type: true, name: customExtension }
        )
      );
      this.resetCustomFileTypeForm();
    } else {
      this.toastr.error('File extension is required');
    }
  }

  public remainingExpectedFileTypeList(filterText?: string) {
    const groupedMap: Map<string, FileType[]> = new Map();

    this.editRequestModalService.availableFileTypes
      .filter(
        (fileType) =>
          this.selectedSection
            .get('expected_files')
            ?.value.find(
              (expectedFile: any) => expectedFile.file_type_id === fileType.id
            ) === undefined &&
          (filterText
            ? fileType.mime_type
                ?.toLowerCase()
                .includes(filterText.toLowerCase()) ||
              fileType.name.toLowerCase().includes(filterText.toLowerCase()) ||
              fileType.group_name
                .toLowerCase()
                .includes(filterText.toLowerCase())
            : true)
      )
      .forEach((fileType) => {
        if (groupedMap.has(fileType.group_name)) {
          groupedMap.get(fileType.group_name)!.push(fileType);
        } else {
          groupedMap.set(fileType.group_name, [fileType]);
        }
      });

    return groupedMap;
  }

  get fileTypeGroups() {
    const groupedMap: Map<string, FileType[]> = new Map([
      ['Print file', []],
      ['Audio file', []],
      ['Document', []],
      ['Image', []],
      ['Video', []],
      ['Code', []],
      ['Font', []],
      ['Other', []],
    ]);

    this.editRequestModalService.availableFileTypes.forEach((fileType) => {
      if (fileType.group_name) {
        fileType.group_name.split(';').map((groupName: string) => {
          if (groupedMap.has(groupName)) {
            groupedMap.get(groupName)!.push(fileType);
          } else {
            groupedMap.set(groupName, [fileType]);
          }
        });
      }
    });

    return groupedMap;
  }

  keepOriginalOrder() {
    return 0;
  }

  public removeExpectedFile(expectedFileIndex: number) {
    (this.selectedSection.controls.expected_files as FormArray).removeAt(
      expectedFileIndex
    );
  }

  get sectionGroupTitleControl() {
    return this.selectedSectionGroup.get('title') as FormControl;
  }

  get hideHeaderTitleInputControl() {
    return this.selectedSectionGroup.get(
      'hide_header_title_input'
    ) as FormControl;
  }

  hideSectionGroupTitleInput() {
    this.hideHeaderTitleInputControl.setValue(true);
  }
}
