import { Component } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { RequestUploadedFile } from '../../backend-services/request.service';
import { FileUploadModalV4Service } from '../file-upload-modal-v4/file-upload-modal-v4.service';

@Component({
  selector: 'app-uploaded-file-validation-data',
  templateUrl: './uploaded-file-validation-data.component.html',
  styleUrls: ['./uploaded-file-validation-data.component.scss'],
})
export class UploadedFileValidationDataComponent {
  constructor(
    public requestViewPageService: RequestViewPageService,
    public fileUploadModalV4Service: FileUploadModalV4Service
  ) {}

  get selectedUploadedFile(): RequestUploadedFile | undefined {
    return this.requestViewPageService.selectedSection?.uploaded_files[
      this.requestViewPageService.selectedUploadFileIndex
    ];
  }
}
