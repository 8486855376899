<app-custom-modal
  [modalEvents]="fileDeleteConfirmationModalService.modalEvents"
  classes="max-w-[450px]"
  [noBody]="true"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Confirm Delete"
  >
    Confirm Delete
  </h3>
  <div footer class="flex w-full justify-between gap-3">
    <button
      class="tw-btn-secondary-outline"
      (click)="closeModal()"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      class="tw-btn-secondary"
      (click)="fileDeleteConfirmationModalService.deleteFile()"
      transloco="Confirm"
    >
      Confirm
    </button>
  </div>
</app-custom-modal>
