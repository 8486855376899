<app-custom-modal
  [modalEvents]="sectionRejectionModalService.modalEvents"
  classes="max-w-[600px]"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Datei ablehnen"
  >
    Datei ablehnen
  </h3>
  <div content-body>
    <p
      class="mb-6 text-base text-primary"
      transloco="Bitte schreiben sie eine kurze Begründung warum Sie die Datei nicht freigeben können."
    >
      Bitte schreiben sie eine kurze Begründung warum Sie die Datei nicht
      freigeben können.
    </p>
    <div class="flex flex-col mb-10">
      <label
        for="section-rejection-modal-comment"
        class="text-primary font-medium mb-1"
        transloco="Begründung"
        >Begründung*</label
      >
      <textarea
        class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
        id="section-rejection-modal-comment"
        rows="5"
        [formControl]="sectionRejectionModalService.rejectionCommentControl"
      ></textarea>

      <div class="self-end text-primary opacity-60 mt-2">
        {{ sectionRejectionModalService.rejectionCommentControl.value.length }}
        / 250
      </div>
    </div>
  </div>
  <div footer class="flex gap-3 w-full justify-between">
    <button
      class="tw-btn-secondary-outline"
      (click)="closeModal()"
      transloco="Abbrechen"
    >
      Abbrechen
    </button>
    <button
      class="tw-btn-secondary"
      (click)="sectionRejectionModalService.rejectCurrentSection()"
      [disabled]="sectionRejectionModalService.rejectionCommentControl.invalid"
      transloco="Send"
    >
      Send
    </button>
  </div>
</app-custom-modal>
