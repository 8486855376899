<div class="container mx-auto font-Satoshi" *transloco="let t">
  <div class="w-full flex justify-between">
    <h1
      transloco="Mein Account"
      class="font-Montserrat font-bold text-2xl text-primary"
    >
      Mein Account
    </h1>
    <button
      transloco="Save"
      class="tw-btn-secondary"
      [disabled]="personalDataFormIsSubmitting"
      (click)="saveProfileData()"
    >
      Save
    </button>
  </div>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-[70px] gap-6">
    <div class="flex flex-col">
      <h2
        transloco="Persönliche Daten"
        class="px-5 font-Montserrat text-xl text-primary font-bold"
      >
        Persönliche Daten
      </h2>
      <div class="mt-[22px] bg-gray16 py-5 px-5 rounded-lg gap-7 flex flex-col">
        <app-form-input
          [label]="t('Firstname')"
          [placeholder]="t('Firstname eingeben') + '...'"
          [fc]="firstNameControl"
          [hasError]="firstNameControl.invalid && firstNameControl.touched"
          [errorMessage]="t('Invalid Firstname')"
        ></app-form-input>
        <app-form-input
          [label]="t('Lastname')"
          [placeholder]="t('Lastname eingeben') + '...'"
          [fc]="lastNameControl"
          [hasError]="lastNameControl.invalid && lastNameControl.touched"
          [errorMessage]="t('Invalid Lastname')"
        ></app-form-input>
        <app-form-input
          [label]="t('E-Mail')"
          [placeholder]="t('E-Mail eingeben') + '...'"
          [fc]="emailNameControl"
          [hasError]="emailNameControl.invalid && emailNameControl.touched"
          [errorMessage]="t('Invalid E-Mail')"
        ></app-form-input>
      </div>
    </div>
    <div class="flex flex-col">
      <h2
        transloco="Passwort"
        class="px-5 font-Montserrat text-xl text-primary font-bold"
      >
        Passwort
      </h2>
      <div class="bg-white flex flex-col p-5 rounded-lg h-full mt-[22px] gap-4">
        <div transloco="Hier können Sie ein neues Passwort setzen:" class="">
          Hier können Sie ein neues Passwort setzen:
        </div>
        <button
          class="tw-btn-blue w-fit"
          transloco="Passwort ändern"
          (click)="openPasswordChangeModal()"
        >
          Passwort ändern
        </button>
      </div>
    </div>
    <div class="flex flex-col" *ngIf="authService.license.value">
      <h2
        transloco="Ihr Paket"
        class="px-5 font-Montserrat text-xl text-primary font-bold"
      >
        Ihr Paket
      </h2>
      <div
        class="flex flex-col p-5 rounded-lg h-full mt-[22px] gap-4 border-light-blue5 border-dashed border-2"
      >
        <div class="flex justify-between border-b border-gray14 pb-4">
          <div class="flex font-medium text-lg items-center gap-4 flex-wrap">
            <div
              class="bg-light-blue5 text-primary rounded-lg font-bold text-[11px] flex justify-center items-center py-[2px] px-[6px] m-1 uppercase"
            >
              {{ authService.license?.value?.package?.name }}
            </div>
          </div>
          <a routerLink="/packages">
            <button
              class="tw-btn-secondary h-fit text-11px 2xl:py-2 px-[14px]"
              transloco="Upgrade"
            >
              Upgrade
            </button>
          </a>
        </div>
        <div class="grid grid-cols-2 my-4 mx-2">
          <div class="flex flex-col gap-4">
            <div class="text-base text-gray8">
              <div
                class="mb-1 text-primary opacity-60 font-medium text-15px"
                transloco="Users"
              >
                Users
              </div>
              <div class="font-bold text-base text-light-blue2">
                {{ authService.license.value.usage.userCount }} /
                <span class="text-orange1">{{
                  authService.license.value.usage.availableUserCount
                }}</span>
              </div>
            </div>
            <div class="text-base text-gray8">
              <div
                class="mb-1 text-primary opacity-60 font-medium text-15px"
                transloco="Anfragen"
              >
                Anfragen
              </div>
              <div class="font-bold text-base text-light-blue2">
                {{ authService.license.value.usage.projectCount }} /
                <span class="text-orange1">{{
                  authService.license.value.usage.availableProjectCount
                }}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div
              transloco="Speicherplatz"
              class="text-primary opacity-60 font-medium text-15px"
            >
              Speicherplatz
            </div>
            <div class="w-fit h-fit relative">
              <svg
                height="120"
                width="120"
                viewBox="0 0 100 100"
                class="rounded-full loader-shadow -rotate-90"
              >
                <circle
                  class="circle"
                  cx="50"
                  cy="50"
                  r="46"
                  stroke="#BACFD7"
                  stroke-width="8"
                  fill-opacity="0"
                />
                <circle
                  class="circle"
                  cx="50"
                  cy="50"
                  r="46"
                  stroke="#FF8811"
                  stroke-width="8"
                  fill-opacity="0"
                  stroke-dasharray="1000"
                  [attr.stroke-dashoffset]="storageDashOffset"
                  stroke-linecap="round"
                />
              </svg>
              <div
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center"
              >
                <div class="font-bold text-11px text-primary whitespace-nowrap">
                  {{
                    authService.license.value.usage.storageUsage | fileSize: 1
                  }}
                  <br />
                  <span class="text-orange1"
                    >/
                    {{
                      authService.license.value.usage.availableStorage
                        | fileSize
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-custom-modal
  [modalEvents]="editPasswordModalEvents"
  classes="max-w-2xl font-Satoshi"
  *transloco="let t"
  maxHeight="600px"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Passwort vergessen"
  >
    Passwort vergessen
  </h3>
  <div content-body class="flex flex-col gap-7">
    <app-form-input
      [label]="t('Altes Passwort')"
      [placeholder]="t('Altes Passwort') + '...'"
      [fc]="passwordControl"
      [hasError]="passwordControl.invalid && passwordControl.touched"
      [errorMessage]="t('Invalid Altes Passwort')"
      inputType="password"
    ></app-form-input>
    <app-form-input
      [label]="t('Neues Passwort')"
      [placeholder]="t('Neues Passwort') + '...'"
      [fc]="newPasswordControl"
      [hasError]="newPasswordControl.invalid && newPasswordControl.touched"
      [errorMessage]="t('Invalid Neues Passwort')"
      inputType="password"
    ></app-form-input>
    <app-form-input
      [label]="t('Passwort wiederholen')"
      [placeholder]="t('Passwort wiederholen') + '...'"
      [fc]="confirmPasswordControl"
      [hasError]="
        confirmPasswordControl.invalid && confirmPasswordControl.touched
      "
      inputType="password"
      [errorMessage]="t('Invalid')"
    ></app-form-input>
    <div class="font-Satoshi font-medium mb-4">
      <i
        transloco="Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten."
        >Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen
        Großbuchstaben und ein Sonderzeichen enthalten.</i
      >
    </div>
  </div>
  <div footer class="w-full flex justify-end">
    <button
      class="tw-btn-secondary"
      [disabled]="passwordDataForm.disabled"
      transloco="Passwort Ändern"
      (click)="savePassword()"
    >
      Passwort Ändern
    </button>
  </div>
</app-custom-modal>
