import { Component } from '@angular/core';
import { SendRequestModalService } from './send-request-modal.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-send-request-modal',
  templateUrl: './send-request-modal.component.html',
  styleUrls: ['./send-request-modal.component.scss'],
})
export class SendRequestModalComponent {
  contactSelectInput = new FormControl('');
  emailList: string[] = [];

  constructor(public sendRequestModalService: SendRequestModalService) {
    this.sendRequestModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'init-close':
          this.sendRequestModalService.modalEvents.next({ type: 'close' });
          break;
      }
    });

    this.contactSelectInput.valueChanges.subscribe((val) => {
      if (val) {
        this.contactSelectInput.setValue('');
        const existingList = this.sendRequestModalService.toControl.value;
        existingList.push(val);
        this.sendRequestModalService.toControl.setValue(existingList);
      }
    });

    this.sendRequestModalService.toControl.valueChanges.subscribe((v) => {
      this.emailList = v;
    });
  }

  closeModal() {
    this.sendRequestModalService.modalEvents.next({ type: 'close' });
  }
}
