import { Component } from '@angular/core';
import { EditUserModalService } from './edit-user-modal.service';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent {
  constructor(public editUserModalService: EditUserModalService) {}

  changeCustomerId(id: number) {
    this.editUserModalService.customerIdControl.setValue(id);
  }
}
