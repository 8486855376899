import {AfterViewInit, Component, OnInit} from '@angular/core';
import 'reflect-metadata';
import { filter, Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { initFlowbite } from 'flowbite';
import { PushNotificationsService } from './push-notifications.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  navigationEnd: Observable<NavigationEnd>;

  constructor(
    private router: Router,
    private pushNotificationService: PushNotificationsService,
    private translocoService: TranslocoService
  ) {
    this.navigationEnd = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    pushNotificationService.register();
  }

  ngOnInit() {
    initFlowbite();
    this.navigationEnd.subscribe(() => {
      initFlowbite();
    });
  }
}
