import { Component, ElementRef, ViewChild } from '@angular/core';
import { FileUploadModalV4Service } from './file-upload-modal-v4.service';
import { RequestViewPageService } from '../request-view-page.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-file-upload-modal-v4',
  templateUrl: './file-upload-modal-v4.component.html',
  styleUrls: ['./file-upload-modal-v4.component.scss'],
})
export class FileUploadModalV4Component {
  @ViewChild('toggleFileUploadModalBtn') toggleFileUploadModalBtn?: ElementRef;
  @ViewChild('showFileUploadModalBtn') showFileUploadModalBtn?: ElementRef;
  @ViewChild('hideFileUploadModalBtn') hideFileUploadModalBtn?: ElementRef;

  constructor(
    public fileUploadModalV4Service: FileUploadModalV4Service,
    public requestViewPageService: RequestViewPageService,
  ) {
  }

  initCloseModal() {
    this.fileUploadModalV4Service.modalEvents.next({
      type: 'init-upload-cancel',
    });
  }

  closeModal() {
    this.fileUploadModalV4Service.modalEvents.next({
      type: 'close',
    });
  }

}
