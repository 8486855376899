<app-custom-modal
  [modalEvents]="fileUploadModalV4Service.modalEvents"
  classes="max-w-[1200px] xl:min-w-[850px]"
  maxHeight="900px"
  *transloco="let t"
>
  <h1
    header
    class="text-xl font-semibold"
    transloco="Datei Upload & Datencheck"
  >
    Datei Upload & Datencheck
  </h1>
  <div content-body>
    <div
      class="mt-8 px-3 py-2 text-white bg-red3 rounded-2xl font-semibold text-sm leading-22.4px text-center"
      *ngIf="fileUploadModalV4Service.validationStatus === 'failed'"
      transloco="Ihre Daten entsprechen nicht den Anforderungen. Bitte überprüfen Sie Ihre Daten."
    >
      Ihre Daten entsprechen nicht den Anforderungen. Bitte überprüfen Sie Ihre
      Daten.
    </div>
    <div
      class="mt-8 px-3 py-2 text-white bg-green3 rounded-2xl font-semibold text-sm leading-22.4px text-center"
      *ngIf="fileUploadModalV4Service.validationStatus === 'success'"
      transloco="Ihre Daten entsprechen den Anforderungen!"
    >
      Ihre Daten entsprechen den Anforderungen!
    </div>
    <div
      class="my-5 p-4 flex justify-center items-center bg-gray8 rounded-2xl font-semibold text-sm leading-22.4px text-primary opacity-80 tracking-0.6px uppercase"
    >
      {{ fileUploadModalV4Service.currentFileName }}
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-2 mt-8">
      <div class="flex flex-col">
        <div class="flex gap-3 items-center">
          <div
            class="h-11 w-11 flex justify-center items-center font-bold text-base leading-25.6px rounded-full duration-500"
            [ngClass]="{
              'bg-primary text-white':
                fileUploadModalV4Service.uploadStatus === 'uploaded',
              'bg-gray11 text-gray7':
                fileUploadModalV4Service.uploadStatus !== 'uploaded'
            }"
          >
            1
          </div>
          <h2
            class="font-bold text-base text-primary leading-25.6px opacity-80"
            *ngIf="fileUploadModalV4Service.uploadStatus === 'uploading'"
            transloco="Uploading ..."
          >
            Uploading ...
          </h2>
          <h2
            class="font-bold text-base text-primary leading-25.6px opacity-80"
            *ngIf="fileUploadModalV4Service.uploadStatus === 'uploaded'"
            transloco="Upload"
          >
            Upload
          </h2>
          <div
            class="flex gap-3"
            *ngIf="fileUploadModalV4Service.uploadStatus === 'error'"
          >
            <h2
              class="font-bold text-base text-primary leading-25.6px opacity-80"
              transloco="Upload fehlgeschlagen"
            >
              Upload fehlgeschlagen
            </h2>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2201_24127)">
                <path
                  d="M13.4726 2.34917C13.3241 2.09052 13.11 1.87562 12.8519 1.72618C12.5938 1.57674 12.3008 1.49805 12.0026 1.49805C11.7043 1.49805 11.4113 1.57674 11.1532 1.72618C10.8951 1.87562 10.681 2.09052 10.5326 2.34917L0.247053 19.8497C-0.438447 21.0167 0.383553 22.5002 1.71705 22.5002H22.2866C23.6201 22.5002 24.4436 21.0152 23.7566 19.8497L13.4726 2.34917ZM11.9996 7.50017C12.8021 7.50017 13.4306 8.19317 13.3496 8.99267L12.8246 14.2532C12.8069 14.4598 12.7124 14.6523 12.5596 14.7926C12.4068 14.9329 12.207 15.0108 11.9996 15.0108C11.7921 15.0108 11.5923 14.9329 11.4395 14.7926C11.2867 14.6523 11.1922 14.4598 11.1746 14.2532L10.6496 8.99267C10.6307 8.80402 10.6516 8.61351 10.7108 8.43342C10.7701 8.25333 10.8664 8.08764 10.9936 7.94704C11.1207 7.80643 11.276 7.69403 11.4492 7.61706C11.6225 7.5401 11.81 7.50028 11.9996 7.50017ZM12.0026 16.5002C12.4004 16.5002 12.7819 16.6582 13.0632 16.9395C13.3445 17.2208 13.5026 17.6023 13.5026 18.0002C13.5026 18.398 13.3445 18.7795 13.0632 19.0608C12.7819 19.3421 12.4004 19.5002 12.0026 19.5002C11.6047 19.5002 11.2232 19.3421 10.9419 19.0608C10.6606 18.7795 10.5026 18.398 10.5026 18.0002C10.5026 17.6023 10.6606 17.2208 10.9419 16.9395C11.2232 16.6582 11.6047 16.5002 12.0026 16.5002Z"
                  fill="#EF3054"
                />
              </g>
              <defs>
                <clipPath id="clip0_2201_24127">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="my-4 flex justify-center items-center">
          <div class="w-fit h-fit relative">
            <svg
              height="260"
              width="260"
              viewBox="0 0 100 100"
              class="bg-white rounded-full loader-shadow -rotate-90"
            >
              <circle
                class="circle"
                cx="50"
                cy="50"
                r="46"
                stroke="#231f2008"
                stroke-width="8"
                fill-opacity="0"
              />
              <circle
                class="circle"
                cx="50"
                cy="50"
                r="46"
                stroke="#FF8811"
                stroke-width="8"
                fill-opacity="0"
                stroke-dasharray="1000"
                [attr.stroke-dashoffset]="
                  1000 - fileUploadModalV4Service.uploadProgress * 2.9
                "
                stroke-linecap="round"
              />
            </svg>
            <div
              class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center"
            >
              <img
                src="assets/imgs/icons/bs-cloud-upload.svg"
                alt="upload icon"
                class="duration-500 overflow-hidden mb-5"
                [ngClass]="{
                  'h-7 w-7': fileUploadModalV4Service.uploadProgress < 100,
                  'h-0 w-0': fileUploadModalV4Service.uploadProgress >= 100
                }"
              />
              <div class="font-bold text-xl leading-28.4px text-primary">
                {{ fileUploadModalV4Service.uploadProgress }}%
              </div>
              <div
                class="font-normal text-sm leading-22.4px text-primary"
                transloco="hochgeladen"
              >
                hochgeladen
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-col">
          <div class="flex gap-3 items-center">
            <div
              class="h-11 w-11 flex justify-center items-center font-bold text-base leading-25.6px rounded-full"
              [ngClass]="{
                'bg-primary text-white':
                  fileUploadModalV4Service.validationStatus === 'success',
                'bg-gray11 text-gray7':
                  fileUploadModalV4Service.validationStatus !== 'success'
              }"
            >
              2
            </div>
            <h2
              class="font-bold text-base text-primary leading-25.6px opacity-80"
              *ngIf="
                fileUploadModalV4Service.validationStatus === 'idle' &&
                fileUploadModalV4Service.uploadStatus !== 'error'
              "
              transloco="Warten auf Datencheck"
            >
              Warten auf Datencheck
            </h2>
            <h2
              class="font-bold text-base text-primary leading-25.6px opacity-80"
              *ngIf="
                fileUploadModalV4Service.validationStatus === 'idle' &&
                fileUploadModalV4Service.uploadStatus === 'error'
              "
              transloco="Datencheck"
            >
              Datencheck
            </h2>
            <h2
              class="font-bold text-base text-primary leading-25.6px opacity-80"
              *ngIf="fileUploadModalV4Service.validationStatus === 'validating'"
              transloco="Datencheck gestartet ..."
            >
              Datencheck gestartet ...
            </h2>
            <h2
              class="font-bold text-base text-primary leading-25.6px opacity-80"
              *ngIf="fileUploadModalV4Service.validationStatus === 'success'"
              transloco="Datencheck erfolgreich"
            >
              Datencheck erfolgreich
            </h2>
            <div
              class="flex gap-3"
              *ngIf="fileUploadModalV4Service.validationStatus === 'failed'"
            >
              <h2
                class="font-bold text-base text-primary leading-25.6px opacity-80"
                transloco="Datencheck fehlgeschlagen"
              >
                Datencheck fehlgeschlagen
              </h2>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_2201_24127)">
                  <path
                    d="M13.4726 2.34917C13.3241 2.09052 13.11 1.87562 12.8519 1.72618C12.5938 1.57674 12.3008 1.49805 12.0026 1.49805C11.7043 1.49805 11.4113 1.57674 11.1532 1.72618C10.8951 1.87562 10.681 2.09052 10.5326 2.34917L0.247053 19.8497C-0.438447 21.0167 0.383553 22.5002 1.71705 22.5002H22.2866C23.6201 22.5002 24.4436 21.0152 23.7566 19.8497L13.4726 2.34917ZM11.9996 7.50017C12.8021 7.50017 13.4306 8.19317 13.3496 8.99267L12.8246 14.2532C12.8069 14.4598 12.7124 14.6523 12.5596 14.7926C12.4068 14.9329 12.207 15.0108 11.9996 15.0108C11.7921 15.0108 11.5923 14.9329 11.4395 14.7926C11.2867 14.6523 11.1922 14.4598 11.1746 14.2532L10.6496 8.99267C10.6307 8.80402 10.6516 8.61351 10.7108 8.43342C10.7701 8.25333 10.8664 8.08764 10.9936 7.94704C11.1207 7.80643 11.276 7.69403 11.4492 7.61706C11.6225 7.5401 11.81 7.50028 11.9996 7.50017ZM12.0026 16.5002C12.4004 16.5002 12.7819 16.6582 13.0632 16.9395C13.3445 17.2208 13.5026 17.6023 13.5026 18.0002C13.5026 18.398 13.3445 18.7795 13.0632 19.0608C12.7819 19.3421 12.4004 19.5002 12.0026 19.5002C11.6047 19.5002 11.2232 19.3421 10.9419 19.0608C10.6606 18.7795 10.5026 18.398 10.5026 18.0002C10.5026 17.6023 10.6606 17.2208 10.9419 16.9395C11.2232 16.6582 11.6047 16.5002 12.0026 16.5002Z"
                    fill="#EF3054"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2201_24127">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="flex flex-col mt-6 gap-4">
            <!--            <div-->
            <!--              *ngIf="requestViewPageService.selectedSection?.requirement_title"-->
            <!--            >-->
            <!--              <div-->
            <!--                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"-->
            <!--                transloco="Anforderung Titel:"-->
            <!--              >-->
            <!--                Anforderung Titel:-->
            <!--              </div>-->
            <!--              <div class="flex gap-1 items-center">-->
            <!--                <img-->
            <!--                  src="assets/imgs/icons/right-arrow.svg"-->
            <!--                  alt=""-->
            <!--                  class="h-4 w-4"-->
            <!--                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"-->
            <!--                />-->
            <!--                <img-->
            <!--                  src="assets/imgs/icons/processing-icon.svg"-->
            <!--                  alt=""-->
            <!--                  class="h-4 w-4 animate-spin"-->
            <!--                  *ngIf="-->
            <!--                    fileUploadModalV4Service.validationStatus === 'validating'-->
            <!--                  "-->
            <!--                />-->
            <!--                <img-->
            <!--                  src="assets/imgs/icons/bs-check-square-fill.svg"-->
            <!--                  alt=""-->
            <!--                  class="h-4 w-4"-->
            <!--                  *ngIf="-->
            <!--                    fileUploadModalV4Service.validationStatus === 'success' ||-->
            <!--                    fileUploadModalV4Service.validationStatus === 'failed'-->
            <!--                  "-->
            <!--                />-->
            <!--                &lt;!&ndash;                  <img&ndash;&gt;-->
            <!--                &lt;!&ndash;                    src="assets/imgs/icons/bs-x-square-fill.svg"&ndash;&gt;-->
            <!--                &lt;!&ndash;                    alt=""&ndash;&gt;-->
            <!--                &lt;!&ndash;                    class="h-4 w-4"&ndash;&gt;-->
            <!--                &lt;!&ndash;                    *ngIf="&ndash;&gt;-->
            <!--                &lt;!&ndash;                      fileUploadModalV4Service.validationStatus === 'failed'&ndash;&gt;-->
            <!--                &lt;!&ndash;                    "&ndash;&gt;-->
            <!--                &lt;!&ndash;                  />&ndash;&gt;-->
            <!--                <div-->
            <!--                  class="font-semibold text-sm leading-22.4px"-->
            <!--                  [ngClass]="{-->
            <!--                    'text-primary':-->
            <!--                      fileUploadModalV4Service.validationStatus === 'idle' ||-->
            <!--                      fileUploadModalV4Service.validationStatus ===-->
            <!--                        'validating',-->
            <!--                    'text-green3':-->
            <!--                      fileUploadModalV4Service.validationStatus === 'success' ||-->
            <!--                      fileUploadModalV4Service.validationStatus === 'failed'-->
            <!--                  }"-->
            <!--                >-->
            <!--                  {{-->
            <!--                    requestViewPageService.selectedSection?.requirement_title-->
            <!--                  }}-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              &lt;!&ndash;                <div&ndash;&gt;-->
            <!--              &lt;!&ndash;                  class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"&ndash;&gt;-->
            <!--              &lt;!&ndash;                  *ngIf="&ndash;&gt;-->
            <!--              &lt;!&ndash;                    fileUploadModalV4Service.validationStatus === 'success' ||&ndash;&gt;-->
            <!--              &lt;!&ndash;                    fileUploadModalV4Service.validationStatus === 'failed'&ndash;&gt;-->
            <!--              &lt;!&ndash;                  "&ndash;&gt;-->
            <!--              &lt;!&ndash;                >&ndash;&gt;-->
            <!--              &lt;!&ndash;                  Error Message&ndash;&gt;-->
            <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
            <!--            </div>-->
            <div
              *ngIf="
                requestViewPageService.selectedSection?.allowed_file_formats
              "
            >
              <div
                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
                transloco="Erlaubte Dateiformate:"
              >
                Erlaubte Dateiformate:
              </div>
              <div class="flex gap-1 items-center">
                <img
                  src="assets/imgs/icons/right-arrow.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"
                />
                <img
                  src="assets/imgs/icons/processing-icon.svg"
                  alt=""
                  class="h-4 w-4 animate-spin"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'validating'
                  "
                />
                <img
                  src="assets/imgs/icons/bs-check-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'success' ||
                    (fileUploadModalV4Service.validationStatus === 'failed' &&
                      !!fileUploadModalV4Service.validationResults?.file_type
                        ?.valid)
                  "
                />
                <img
                  src="assets/imgs/icons/bs-x-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'failed' &&
                    !fileUploadModalV4Service.validationResults?.file_type
                      ?.valid
                  "
                />
                <div
                  class="font-semibold text-sm leading-22.4px"
                  [ngClass]="{
                    'text-primary':
                      fileUploadModalV4Service.validationStatus === 'idle' ||
                      fileUploadModalV4Service.validationStatus ===
                        'validating',
                    'text-green3':
                      fileUploadModalV4Service.validationStatus === 'success' ||
                      (fileUploadModalV4Service.validationStatus === 'failed' &&
                        !!fileUploadModalV4Service.validationResults?.file_type
                          ?.valid),
                    'text-red3':
                      fileUploadModalV4Service.validationStatus === 'failed' &&
                      !fileUploadModalV4Service.validationResults?.file_type
                        ?.valid
                  }"
                >
                  {{
                    requestViewPageService.selectedSection?.allowed_file_formats
                  }}
                </div>
              </div>
              <div
                class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"
                *ngIf="
                  fileUploadModalV4Service.validationStatus === 'failed' &&
                  !fileUploadModalV4Service.validationResults?.file_type?.valid
                "
              >
                {{
                  t(
                    fileUploadModalV4Service.validationResults?.file_type
                      ?.message || ""
                  )
                }}
              </div>
            </div>
            <div *ngIf="requestViewPageService.selectedSection?.color_space">
              <div
                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
                transloco="Farbraum:"
              >
                Farbraum:
              </div>
              <div class="flex gap-1 items-center">
                <img
                  src="assets/imgs/icons/right-arrow.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"
                />
                <img
                  src="assets/imgs/icons/processing-icon.svg"
                  alt=""
                  class="h-4 w-4 animate-spin"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'validating'
                  "
                />
                <img
                  src="assets/imgs/icons/bs-check-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'success' ||
                    (fileUploadModalV4Service.validationStatus === 'failed' &&
                      !!fileUploadModalV4Service.validationResults?.color_space
                        ?.valid)
                  "
                />
                <img
                  src="assets/imgs/icons/bs-x-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'failed' &&
                    !fileUploadModalV4Service.validationResults?.color_space
                      ?.valid
                  "
                />
                <div
                  class="font-semibold text-sm leading-22.4px"
                  [ngClass]="{
                    'text-primary':
                      fileUploadModalV4Service.validationStatus === 'idle' ||
                      fileUploadModalV4Service.validationStatus ===
                        'validating',
                    'text-green3':
                      fileUploadModalV4Service.validationStatus === 'success' ||
                      (fileUploadModalV4Service.validationStatus === 'failed' &&
                        !!fileUploadModalV4Service.validationResults
                          ?.color_space?.valid),
                    'text-red3':
                      fileUploadModalV4Service.validationStatus === 'failed' &&
                      !fileUploadModalV4Service.validationResults?.color_space
                        ?.valid
                  }"
                >
                  {{ requestViewPageService.selectedSection?.color_space }}
                </div>
              </div>
              <div
                class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"
                *ngIf="
                  fileUploadModalV4Service.validationStatus === 'failed' &&
                  !fileUploadModalV4Service.validationResults?.color_space
                    ?.valid
                "
              >
                {{
                  t(
                    fileUploadModalV4Service.validationResults?.color_space
                      ?.message || ""
                  )
                }}
              </div>
            </div>
            <div *ngIf="requestViewPageService.selectedSection?.dimensions">
              <div
                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
                transloco="Dokumentformat oder Abmessungen:"
              >
                Dokumentformat oder Abmessungen:
              </div>
              <div class="flex gap-1 items-center">
                <img
                  src="assets/imgs/icons/right-arrow.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"
                />
                <img
                  src="assets/imgs/icons/processing-icon.svg"
                  alt=""
                  class="h-4 w-4 animate-spin"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'validating'
                  "
                />
                <img
                  src="assets/imgs/icons/bs-check-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'success' ||
                    (fileUploadModalV4Service.validationStatus === 'failed' &&
                      !!fileUploadModalV4Service.validationResults?.dimensions
                        ?.valid)
                  "
                />
                <img
                  src="assets/imgs/icons/bs-x-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'failed' &&
                    !fileUploadModalV4Service.validationResults?.dimensions
                      ?.valid
                  "
                />
                <div
                  class="font-semibold text-sm leading-22.4px"
                  [ngClass]="{
                    'text-primary':
                      fileUploadModalV4Service.validationStatus === 'idle' ||
                      fileUploadModalV4Service.validationStatus ===
                        'validating',
                    'text-green3':
                      fileUploadModalV4Service.validationStatus === 'success' ||
                      (fileUploadModalV4Service.validationStatus === 'failed' &&
                        !!fileUploadModalV4Service.validationResults?.dimensions
                          ?.valid),
                    'text-red3':
                      fileUploadModalV4Service.validationStatus === 'failed' &&
                      !fileUploadModalV4Service.validationResults?.dimensions
                        ?.valid
                  }"
                >
                  {{
                    fileUploadModalV4Service.translateDimensionsText(
                      requestViewPageService.selectedSection?.dimensions
                    )
                  }}
                </div>
              </div>
              <div
                class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"
                *ngIf="
                  fileUploadModalV4Service.validationStatus === 'failed' &&
                  !fileUploadModalV4Service.validationResults?.dimensions?.valid
                "
              >
                {{
                  t(
                    fileUploadModalV4Service.validationResults?.dimensions
                      ?.message || ""
                  )
                }}
              </div>
            </div>

            <div *ngIf="requestViewPageService.selectedSection?.resolution">
              <div
                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
                transloco="Minimale Auflösung:"
              >
                Minimale Auflösung:
              </div>
              <div class="flex gap-1 items-center">
                <img
                  src="assets/imgs/icons/right-arrow.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"
                />
                <img
                  src="assets/imgs/icons/processing-icon.svg"
                  alt=""
                  class="h-4 w-4 animate-spin"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'validating'
                  "
                />
                <img
                  src="assets/imgs/icons/bs-check-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'success' ||
                    (fileUploadModalV4Service.validationStatus === 'failed' &&
                      !!fileUploadModalV4Service.validationResults?.resolution
                        ?.valid)
                  "
                />
                <img
                  src="assets/imgs/icons/bs-x-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'failed' &&
                    !fileUploadModalV4Service.validationResults?.resolution
                      ?.valid
                  "
                />
                <div
                  class="font-semibold text-sm leading-22.4px"
                  [ngClass]="{
                    'text-primary':
                      fileUploadModalV4Service.validationStatus === 'idle' ||
                      fileUploadModalV4Service.validationStatus ===
                        'validating',
                    'text-green3':
                      fileUploadModalV4Service.validationStatus === 'success' ||
                      (fileUploadModalV4Service.validationStatus === 'failed' &&
                        !!fileUploadModalV4Service.validationResults?.resolution
                          ?.valid),
                    'text-red3':
                      fileUploadModalV4Service.validationStatus === 'failed' &&
                      !fileUploadModalV4Service.validationResults?.resolution
                        ?.valid
                  }"
                >
                  {{ requestViewPageService.selectedSection?.resolution }}
                </div>
              </div>
              <div
                class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"
                *ngIf="
                  fileUploadModalV4Service.validationStatus === 'failed' &&
                  !fileUploadModalV4Service.validationResults?.resolution?.valid
                "
              >
                {{
                  t(
                    fileUploadModalV4Service.validationResults?.resolution
                      ?.message || ""
                  )
                }}
              </div>
            </div>

            <div *ngIf="requestViewPageService.selectedSection?.max_file_size">
              <div
                class="text-primary opacity-50 font-semibold text-sm leading-22.4px"
                transloco="Maximale Dateigröße:"
              >
                Maximale Dateigröße:
              </div>
              <div class="flex gap-1 items-center">
                <img
                  src="assets/imgs/icons/right-arrow.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="fileUploadModalV4Service.validationStatus === 'idle'"
                />
                <img
                  src="assets/imgs/icons/processing-icon.svg"
                  alt=""
                  class="h-4 w-4 animate-spin"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'validating'
                  "
                />
                <img
                  src="assets/imgs/icons/bs-check-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'success' ||
                    (fileUploadModalV4Service.validationStatus === 'failed' &&
                      !!fileUploadModalV4Service.validationResults?.file_size
                        ?.valid)
                  "
                />
                <img
                  src="assets/imgs/icons/bs-x-square-fill.svg"
                  alt=""
                  class="h-4 w-4"
                  *ngIf="
                    fileUploadModalV4Service.validationStatus === 'failed' &&
                    !fileUploadModalV4Service.validationResults?.file_size
                      ?.valid
                  "
                />
                <div
                  class="font-semibold text-sm leading-22.4px"
                  [ngClass]="{
                    'text-primary':
                      fileUploadModalV4Service.validationStatus === 'idle' ||
                      fileUploadModalV4Service.validationStatus ===
                        'validating',
                    'text-green3':
                      fileUploadModalV4Service.validationStatus === 'success' ||
                      (fileUploadModalV4Service.validationStatus === 'failed' &&
                        !!fileUploadModalV4Service.validationResults?.file_size
                          ?.valid),
                    'text-red3':
                      fileUploadModalV4Service.validationStatus === 'failed' &&
                      !fileUploadModalV4Service.validationResults?.file_size
                        ?.valid
                  }"
                >
                  {{
                    requestViewPageService.selectedSection!.max_file_size * 1024
                      | fileSize
                  }}
                </div>
              </div>
              <div
                class="text-sm font-medium leading-19.6px -tracking-0.01em text-red3"
                *ngIf="
                  fileUploadModalV4Service.validationStatus === 'failed' &&
                  !fileUploadModalV4Service.validationResults?.file_size?.valid
                "
              >
                {{
                  t(
                    fileUploadModalV4Service.validationResults?.file_size
                      ?.message || ""
                  )
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div footer class="w-full justify-between flex">
    <button
      class="tw-btn-secondary-outline px-8"
      [disabled]="
        !(
          fileUploadModalV4Service.validationStatus !== 'failed' &&
          fileUploadModalV4Service.validationStatus !== 'success'
        )
      "
      (click)="initCloseModal()"
    >
      Abbrechen
    </button>
    <button
      class="tw-btn-secondary px-8"
      [disabled]="
        !(
          fileUploadModalV4Service.validationStatus === 'failed' ||
          fileUploadModalV4Service.validationStatus === 'success'
        )
      "
      (click)="closeModal()"
      transloco="Schließen"
    >
      Schließen
    </button>
  </div>
</app-custom-modal>
<app-upload-cancel-confirm-modal></app-upload-cancel-confirm-modal>
