<div class="flex justify-between items-center py-4 px-5 bg-gray16 rounded-t-lg">
  <h3 class="font-Montserrat text-lg text-black font-bold">
    {{ requestViewPageService.selectedSection?.title }}
  </h3>
  <app-status-display
    [statusId]="requestViewPageService.selectedSectionStatusId()"
    [variant]="1"
  ></app-status-display>
</div>
<app-file-section-actions-bar
  *ngIf="showFileSectionActionBar"
></app-file-section-actions-bar>
<div class="flex bg-white py-4 px-6" *transloco="let t;">
  <div class="flex-grow w-full">
    <div
      class="opacity-60 text-sm font-medium text-blue2"
    >
      {{ t(requestViewPageService.selectedSection?.expected_file_group || '') }}
    </div>
    <p class="mt-3 font-normal text-15px leading-6 text-primary">
      {{ requestViewPageService.selectedSection?.description }}
    </p>
  </div>
  <div *ngIf="sectionThumbnail" class="ml-8">
    <img
      class="w-36 h-28 object-contain border rounded-xl"
      [src]="sectionThumbnail"
      alt=""
    />
  </div>
</div>
