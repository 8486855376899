import { Component } from '@angular/core';
import { RequestViewPageService } from '../request-view-page.service';
import { SectionApprovalModalService } from '../section-approval-modal/section-approval-modal.service';
import { SectionRejectionModalService } from '../section-rejection-modal/section-rejection-modal.service';
import { ProducerPickerModalV4Service } from '../producer-picker-modal-v4/producer-picker-modal-v4.service';
import { UploadProofService } from '../upload-proof-modal/upload-proof.service';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { RequestService } from '../../backend-services/request.service';
import {ToastrService} from "ngx-toastr";
import {TranslocoService} from "@ngneat/transloco";

@Component({
  selector: 'app-file-section-actions-bar',
  templateUrl: './file-section-actions-bar.component.html',
  styleUrls: ['./file-section-actions-bar.component.scss'],
})
export class FileSectionActionsBarComponent {
  proofDeleteConfirmEvents = new Subject<ModalEvents>();

  constructor(
    public requestViewPageService: RequestViewPageService,
    private sectionApprovalModalService: SectionApprovalModalService,
    private sectionRejectionModalService: SectionRejectionModalService,
    private producerPickerModalV4Service: ProducerPickerModalV4Service,
    private uploadProofService: UploadProofService,
    private requestService: RequestService,
    private toastr: ToastrService,
    private translocoService: TranslocoService,
  ) {
    this.proofDeleteConfirmEvents.subscribe((ev) => {
      if (ev.type === 'init-close') {
        this.proofDeleteConfirmEvents.next({ type: 'close' });
      }
    });
  }

  downloadCurrentFile() {
    const file =
      this.requestViewPageService.selectedSection!.uploaded_files[
        this.requestViewPageService.selectedUploadFileIndex
      ];
    this.requestViewPageService.downloadFile(file.url, file.title);
  }

  openApprovalModal() {
    this.sectionApprovalModalService.modalEvents.next({ type: 'open' });
  }

  openUploadProofModal() {
    this.uploadProofService.modalEvents.next({ type: 'open' });
  }

  openRejectionModal() {
    this.sectionRejectionModalService.modalEvents.next({ type: 'open' });
  }

  openProducerPickerModal() {
    this.producerPickerModalV4Service.modalEvents.next({ type: 'open' });
  }

  get showProducerButton() {
    return (
      this.requestViewPageService.isAgency &&
      this.requestViewPageService.selectedSection?.status === 2
    );
  }

  get showUploadProofButton() {
    const proofNeeded =
      this.requestViewPageService.selectedSection?.expected_files.filter(
        (f) => !f.proof_required
      ).length === 0;

    if (!proofNeeded || this.proofFile) {
      return false;
    }

    if (this.requestViewPageService.isAgency) {
      return this.requestViewPageService.selectedSection?.status === 2;
    }

    if (this.requestViewPageService.producerHash) {
      return this.requestViewPageService.selectedSection?.status === 3;
    }

    return false;
  }

  get proofFile() {
    return this.requestViewPageService.selectedSection?.proof_file;
  }

  get isProofFilePending() {
    if (this.requestViewPageService.isAgency) {
      return (
        this.requestViewPageService.selectedSection?.status === 203 ||
        this.requestViewPageService.selectedSection?.status === 104
      );
    }
    if (this.requestViewPageService.producerHash) {
      return this.requestViewPageService.selectedSection?.status === 303;
    }

    return false;
  }

  get showActionButtons() {
    if (this.requestViewPageService.isAgency) {
      return this.requestViewPageService.selectedSection?.status === 2;
    }

    if (this.requestViewPageService.producerHash) {
      return this.requestViewPageService.selectedSection?.status === 3;
    }

    if (this.requestViewPageService.selectedSection?.status === 106) {
      return true;
    }

    return false;
  }

  showProofDeleteConfirmModal() {
    this.proofDeleteConfirmEvents.next({ type: 'open' });
  }

  closeProofDeleteConfirmModal() {
    this.proofDeleteConfirmEvents.next({ type: 'close' });
  }

  handleProofDelete() {
    const sectionGroupIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionIndex;

    if (
      sectionGroupIndex !== undefined &&
      sectionIndex !== undefined &&
      this.requestViewPageService.requestData &&
      this.requestViewPageService.requestData.section_groups
    ) {
      const currentSection =
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
          ].sections[sectionIndex];

      if (this.requestViewPageService.producerHash) {
        currentSection.status = -303;
        this.requestService
          .submitRequestSectionAsProducerV5(
            this.requestViewPageService.requestPublicHash,
            {
              producer_hash: this.requestViewPageService.producerHash,
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.message ||
                this.translocoService.translate(`Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Proof file removed'));
              this.requestViewPageService.fetchRequest();
            },
          })
      } else if (this.requestViewPageService.isAgency) {
        currentSection.status = -203;
        this.requestService
          .submitRequestSectionAsAgencyV5(
            this.requestViewPageService.requestPublicHash,
            {
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                res.error?.message ||
                this.translocoService.translate(`Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Proof file removed'));
              this.requestViewPageService.fetchRequest();
            },
          })
      }
      this.closeProofDeleteConfirmModal();
    }




  }
}
