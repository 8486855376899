<app-custom-modal
  [modalEvents]="createFolderModalService.modalEvents"
  classes="max-w-2xl font-Satoshi"
>
  <h1
    header
    class="text-xl font-semibold"
    *ngIf="!createFolderModalService.isEditing"
    transloco="Create Folder"
  >
    Create Folder
  </h1>
  <h1
    header
    class="text-xl font-semibold"
    *ngIf="createFolderModalService.isEditing"
    transloco="Edit Folder"
  >
    Edit Folder
  </h1>
  <form
    [formGroup]="createFolderModalService.createFolderForm"
    content-body
    class="w-full flex flex-col gap-6 pb-6"
    *transloco="let t"
  >
    <div *isSuperAdmin class="flex flex-col gap-[5px]">
      <div>
        <app-label class="font-bold text-sm" transloco="Customer"
          >Customer</app-label
        >
        <div
          class="border border-gray3 rounded-lg focus:border-gray3 focus:ring-2 focus:ring-gray3 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px"
          *ngIf="createFolderModalService.isEditing"
        >
          {{ selectedCustomer?.label || " - " }}
        </div>
        <app-custom-dropdown
          *ngIf="!createFolderModalService.isEditing"
          [buttonText]="selectedCustomer?.label || ''"
        >
          <li
            *ngFor="let customer of allCustomers"
            class="py-2 px-4 min-w-[400px] cursor-pointer"
            (click)="changeCustomerId(customer.id)"
          >
            {{ customer.label }}
          </li>
        </app-custom-dropdown>
      </div>
    </div>
    <div *ngIf="createFolderModalService.isEditing">
      <app-label class="font-bold text-sm" transloco="Parent Folder"
        >Parent Folder</app-label
      >
      <app-custom-dropdown
        [buttonText]="
          createFolderModalService.parentIdControl.value
            ? requestFolderService.localFolderNameMap.get(
                createFolderModalService.parentIdControl.value
              )
            : '/'
        "
      >
        <ng-template #recursiveList let-list>
          <li *ngFor="let requestFolder of list" class="border-l">
            <button
              class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
              (click)="changeParentFolderValue(requestFolder.id)"
            >
              {{ requestFolder.title }}
            </button>
            <ul *ngIf="requestFolder.children?.length" class="ml-3">
              <ng-container
                *ngTemplateOutlet="
                  recursiveList;
                  context: { $implicit: requestFolder.children }
                "
              ></ng-container>
            </ul>
          </li>
        </ng-template>
        <li class="border-l">
          <button
            class="flex gap-2 my-1 py-1 px-2 cursor-pointer hover:bg-gray-200 text-primary font-medium duration-200 w-full"
            (click)="changeParentFolderValue(undefined)"
          >
            /
          </button>
        </li>
        <ng-container
          *ngTemplateOutlet="
            recursiveList;
            context: {
              $implicit: createFolderModalService.availableParentFolderList
            }
          "
        ></ng-container>
      </app-custom-dropdown>
    </div>
    <app-form-input
      [label]="t('Title')"
      [placeholder]="t('Title eingeben') + '...'"
      [fc]="createFolderModalService.titleControl"
      [hasError]="
        createFolderModalService.titleControl.invalid &&
        createFolderModalService.titleControl.touched
      "
      [errorMessage]="t('Invalid')"
    ></app-form-input>
  </form>
  <div footer class="w-full flex items-center justify-end gap-4 flex-wrap">
    <div class="flex-grow flex" *ngIf="createFolderModalService.isEditing">
      <button
        class="transition-all duration-200 text-white bg-semantic-danger-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
        type="button"
        (click)="initDeleteFolder()"
        [disabled]="createFolderModalService.createFolderForm.disabled"
        transloco="Delete"
      >
        Delete
      </button>
    </div>
    <button
      class="transition-all duration-200 text-primary bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
      type="button"
      (click)="closeModal()"
      [disabled]="createFolderModalService.createFolderForm.disabled"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      class="duration-200 transition-all text-white bg-primary hover:bg-darkBlue1 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-xs 2xl:text-sm px-4 py-2 2xl:px-6 2xl:py-4 text-center"
      type="button"
      (click)="createFolderModalService.handleSubmit()"
      [disabled]="createFolderModalService.createFolderForm.disabled"
    >
      <span
        *ngIf="createFolderModalService.isEditing"
        transloco="Update"
        >Update</span
      >
      <span
        *ngIf="!createFolderModalService.isEditing"
        transloco="Create"
        >Create</span
      >
    </button>
  </div>
</app-custom-modal>
<app-custom-modal
  [modalEvents]="createFolderModalService.deleteConfirmModalEvents"
  classes="max-w-lg"
>
  <h1 header class="text-xl font-semibold" transloco="Delete Confirmation">
    Delete Confirmation
  </h1>
  <div content-body class="p-4 md:p-5 text-center">
    <svg
      class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
      />
    </svg>
    <h3
      class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400"
      transloco="This action will delete all subfolders & requests inside. Are you sure you want to remove this Folder?"
    >
      This action will delete all subfolders & requests inside. Are you sure you
      want to remove this Folder?
    </h3>
  </div>
  <div footer class="flex justify-between w-full">
    <button
      (click)="closeDeleteConfirmModal()"
      type="button"
      class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
      transloco="No"
    >
      No
    </button>
    <button
      type="button"
      (click)="handleFolderDelete()"
      class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-xs 2xl:text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
      transloco="Yes"
    >
      Yes
    </button>
  </div>
</app-custom-modal>
