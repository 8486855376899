import { Component, ElementRef, ViewChild } from '@angular/core';
import { ProducerPickerModalV4Service } from './producer-picker-modal-v4.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-producer-picker-modal-v4',
  templateUrl: './producer-picker-modal-v4.component.html',
  styleUrls: ['./producer-picker-modal-v4.component.scss'],
})
export class ProducerPickerModalV4Component {
  @ViewChild('showProducerPickerModalV4Btn')
  showProducerPickerModalV4Btn?: ElementRef;
  @ViewChild('hideProducerPickerModalV4Btn')
  hideProducerPickerModalV4Btn?: ElementRef;

  contactSelectInput = new FormControl('');

  constructor(
    public producerPickerModalV4Service: ProducerPickerModalV4Service
  ) {
    this.contactSelectInput.valueChanges.subscribe((val) => {
      if (val) {
        this.contactSelectInput.setValue('');
        const existingList = this.producerPickerModalV4Service.toControl.value;
        existingList.push(val);
        this.producerPickerModalV4Service.toControl.setValue(existingList);
      }
    });
  }

  closeModal() {
    this.producerPickerModalV4Service.modalEvents.next({ type: 'close' });
  }

  initProducerPickerModalClose() {
    this.closeModal();
  }
}
