<div *transloco="let t" class="font-Satoshi" [formGroup]="selectedSection">
  <div
    class="flex items-center justify-between px-6 py-6 bg-light-blue2 text-white rounded-t-lg font-Satoshi"
  >
    <h2
      class="font-Montserrat text-lg font-bold"
      *ngIf="hideHeaderTitleInputControl.value"
    >
      {{ selectedSectionGroup.get("title")?.value || t("Unbenanntes Produkt") }}
    </h2>
    <input
      [formControl]="sectionGroupTitleControl"
      (blur)="hideSectionGroupTitleInput()"
      *ngIf="!hideHeaderTitleInputControl.value"
      class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 w-full text-white text-15px bg-transparent font-Montserrat text-lg font-bold max-w-[400px]"
    />
    <div class="text-15px font-bold uppercase">
      # {{ t("Section") }}
      {{ editRequestModalService.selectedSectionIndex + 1 }}
    </div>
  </div>
  <div
    class="bg-white rounded-b-lg p-6 grid grid-cols-1 lg:grid-cols-2 gap-x-16 gap-y-6"
  >
    <div class="flex flex-col gap-2">
      <label class="font-bold text-sm flex" for="section-title"
        ><span transloco="Dateibezeichnung">Dateibezeichnung</span>*
      </label>
      <input
        type="text"
        formControlName="title"
        id="section-title"
        [placeholder]="t('Input section title...')"
        class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
        [class.border-red-400]="
          selectedSection.get('title')?.touched &&
          selectedSection.get('title')?.invalid
        "
      />
      <div
        class="text-red-400"
        *ngIf="
          selectedSection.get('title')?.touched &&
          selectedSection.get('title')?.invalid
        "
      >
        <small
          *ngIf="selectedSection.get('title')?.errors?.required"
          transloco="required"
        >
          required
        </small>
        <small
          *ngIf="selectedSection.get('title')?.errors?.maxlength"
          transloco="Must be less than 255 characters"
        >
          Must be less than 255 characters
        </small>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <h4 class="font-bold text-sm" transloco="Dateityp">Dateityp</h4>
      <div class="bg-white">
        <app-custom-dropdown [buttonText]="t('Select a File Type')">
          <li header>
            <div class="relative min-w-[380px] flex-grow my-3 mx-2">
              <input
                type="search"
                [formControl]="fileTypeFilterText"
                class="block w-full p-3 2xl:p-4 text-xs 2xl:text-sm text-gray-900 border border-gray-300 rounded-lg focus:ring-darkBlue1 focus:border-darkBlue1"
                [placeholder]="t('Search file types...')"
              />
            </div>
          </li>
          <li
            *ngFor="
              let fileTypeGroup of fileTypeGroups | keyvalue: keepOriginalOrder
            "
          >
            <div
              class="flex flex-col cursor-pointer hover:bg-gray-200 p-2"
              (click)="selectFileGroup(fileTypeGroup.value)"
            >
              <div class="mb-2 font-bold text-sm">
                {{
                  t(
                    fileTypeGroup.key === "Other"
                      ? "OTHER_FILE_TYPE_GROUP"
                      : fileTypeGroup.key
                  )
                }}
              </div>
              <div class="flex gap-3 my-1 py-2 flex-wrap">
                <div *ngFor="let fileType of fileTypeGroup.value">
                  <img
                    *ngIf="fileType.icon_svg"
                    [alt]="fileType.name"
                    [src]="
                      sanitizer.bypassSecurityTrustResourceUrl(
                        fileType.icon_svg
                      )
                    "
                    class="w-8 h-8"
                  />
                </div>
              </div>
            </div>
          </li>
        </app-custom-dropdown>
      </div>
    </div>
    <div class="flex flex-col gap-2 mt-6 col-span-2">
      <label
        for="section-description"
        class="font-bold text-sm flex"
        transloco="Description"
        >Description</label
      >
      <textarea
        id="section-description"
        formControlName="description"
        rows="4"
        class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
        [placeholder]="t('Input description...')"
        [class.border-red-400]="
          selectedSection.get('description')?.touched &&
          selectedSection.get('description')?.invalid
        "
      ></textarea>
      <div
        class="text-red-400"
        *ngIf="
          selectedSection.get('description')?.touched &&
          selectedSection.get('description')?.invalid
        "
      >
        <small
          *ngIf="selectedSection.get('description')?.errors?.required"
          transloco="required"
        >
          required
        </small>
        <small
          *ngIf="selectedSection.get('description')?.errors?.maxlength"
          transloco="Must be less than 1000 characters"
        >
          Must be less than 1000 characters
        </small>
      </div>
    </div>
  </div>
  <div class="bg-white rounded-lg px-6 py-5 mt-6">
    <h3 transloco="Anforderungen" class="text-primary text-lg font-bold">
      Anforderungen
    </h3>
    <div class="grid grid-cols-1 lg:grid-cols-9 gap-6">
      <div class="w-full flex flex-col lg:col-span-5">
        <div class="flex flex-col gap-2 mt-7">
          <label class="font-bold text-sm" transloco="Zulässige Dateiformate"
            >Zulässige Dateiformate</label
          >
          <div class="flex gap-[10px] flex-wrap">
            <div
              *ngFor="
                let expectedFile of $any(selectedSection).controls
                  .expected_files.controls;
                let expectedFileIndex = index
              "
            >
              <div
                *ngIf="expectedFile.value.is_picked"
                (click)="expectedFile.get('is_picked').setValue(false)"
                class="cursor-pointer"
              >
                <img
                  *ngIf="
                    fileTypeService.getLocalFileTypeById(
                      expectedFile.value.file_type_id
                    );
                    let expectedFileType
                  "
                  [alt]="expectedFileType.name"
                  [title]="expectedFileType.name"
                  class="w-10 h-10"
                  [src]="
                    sanitizer.bypassSecurityTrustResourceUrl(
                      expectedFileType.icon_svg || ''
                    )
                  "
                />
              </div>
              <div
                *ngIf="
                  !expectedFile.value.is_picked &&
                    fileTypeService.getLocalFileTypeById(
                      expectedFile.value.file_type_id
                    );
                  let expectedFileType
                "
                class="h-10 min-w-[40px] py-[10.5px] px-1 rounded border border-gray3 flex justify-center items-center text-xs font-bold text-primary cursor-pointer"
                (click)="expectedFile.get('is_picked').setValue(true)"
              >
                {{ expectedFileType.name }}
              </div>
              <div
                *ngIf="expectedFile.value.is_custom_type"
                class="h-10 min-w-[40px] py-[10.5px] px-1 rounded border border-gray3 flex justify-center items-center text-xs font-bold text-primary cursor-pointer bg-gray1"
                (click)="removeExpectedFile(expectedFileIndex)"
              >
                {{ expectedFile.value.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            selectedSection.get('expected_files')?.errors &&
            selectedSection.touched
          "
          class="p-4 mb-4 mt-4 text-xs 2xl:text-sm text-red-800 rounded-lg bg-red-50"
          transloco="At least one expected file is required"
        >
          At least one expected file is required
        </div>
        <div
          *ngIf="!showCustomFileTypeForm"
          class="text-gray15 text-15px font-medium hover:text-gray1 py-3 w-fit cursor-pointer"
          (click)="initAddCustomFileType()"
          transloco="+ Custom File Type"
        >
          + Custom File Type
        </div>
        <div *ngIf="showCustomFileTypeForm" class="pr-4">
          <div
            class="text-xs 2xl:text-sm font-medium mt-8 mb-2"
            transloco="Enter the custom file extension ( eg: pdf )"
          >
            Enter the custom file extension ( eg: pdf )
          </div>
          <form class="flex flex-wrap gap-2">
            <input
              class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary"
              [formControl]="customExtensionControl"
              required
              type="text"
            />
            <button
              type="button"
              class="tw-btn-blue"
              (click)="addCustomFileType()"
              transloco="Add custom file type"
            >
              Add custom file type
            </button>
          </form>
        </div>
      </div>
      <div class="flex gap-12 lg:mt-7 mt-4 lg:col-span-4">
        <div class="flex flex-col gap-2 flex-wrap">
          <label
            for="maximum-file-size-input"
            class="font-bold text-sm"
            transloco="Max File Size"
            >Max File Size</label
          >
          <div class="flex justify-between grow gap-4">
            <input
              type="number"
              formControlName="max_file_size"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full grow max-w-[140px] h-fit"
              id="maximum-file-size-input"
            />
            <select
              formControlName="file_size_unit"
              class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full max-w-[120px] h-fit"
            >
              <option value="KB">KB</option>
              <option value="MB">MB</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-7 pt-8 border-t border-gray3 grid grid-cols-1 lg:grid-cols-9 gap-6"
      [formGroup]="generatedValidations"
      *ngIf="canEnforceDimensions"
    >
      <div class="lg:col-span-5">
        <div>
          <label class="flex items-center gap-[9px]">
            <input
              type="checkbox"
              value=""
              class="sr-only"
              formControlName="validate_dimensions"
              id="validate-dimensions"
            />
            <span class="custom-checkbox"></span>
            <span class="font-bold text-sm" transloco="Abmessungen definieren"
              >Abmessungen definieren</span
            >
          </label>
        </div>
        <div class="w-full mt-6" *ngIf="isValidatingDimensions">
          <div class="w-full flex gap-x-3 mb-4">
            <div class="max-w-[120px]">
              <label
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                for="dimensions-width"
                transloco="Dimensions Width"
                >Dimensions Width</label
              >
              <div class="w-full flex">
                <input
                  type="number"
                  id="dimensions-width"
                  formControlName="dimensions_width"
                  class="block px-1 py-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-gray-100 border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 font-Satoshi text-15px font-medium"
                  [placeholder]="t('Enter width')"
                />
              </div>
            </div>
            <div class="max-w-[120px]">
              <label
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                for="dimensions-height"
                transloco="Dimensions Height"
                >Dimensions Height</label
              >
              <div class="w-full flex">
                <input
                  type="number"
                  id="dimensions-height"
                  formControlName="dimensions_height"
                  class="block px-1 py-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-gray-100 border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 font-Satoshi text-15px font-medium"
                  [placeholder]="t('Enter height')"
                />
              </div>
            </div>
            <div class="max-w-[120px]">
              <label
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                for="dimensions-bleed"
                transloco="Bleed"
                >Bleed</label
              >
              <div class="w-full flex">
                <input
                  type="number"
                  id="dimensions-bleed"
                  formControlName="dimensions_bleed"
                  class="block px-1 py-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-gray-100 border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 font-Satoshi text-15px font-medium"
                  [placeholder]="t('Enter Bleed')"
                />
              </div>
            </div>
            <div class="max-w-[100px]">
              <label
                class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
                for="dimensions-unit"
                transloco="Unit"
                >Unit</label
              >
              <select
                id="dimensions-unit"
                formControlName="dimensions_unit"
                class="block px-1 py-2.5 w-full z-20 text-xs 2xl:text-sm text-primary rounded-gray-100 border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 font-Satoshi text-15px font-medium"
              >
                <option selected value="px">px</option>
                <option value="cm">cm</option>
                <option value="mm">mm</option>
                <option value="in">in</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="canEnforceColorSpace" class="lg:col-span-4">
        <label class="flex items-center gap-[9px]">
          <input
            type="checkbox"
            value=""
            class="sr-only"
            formControlName="validate_color_space"
            id="validate-color-space"
          />
          <span class="custom-checkbox"></span>
          <span class="font-bold text-sm" transloco="Farbraum definieren"
            >Farbraum definieren</span
          >
        </label>
        <div
          class="w-full flex mt-4 gap-2 font-medium text-primary uppercase"
          *ngIf="isEnforcingColorspace"
        >
          <button
            class="border border-gray3 rounded-[50px] py-1 px-4"
            [ngClass]="{
              'border-2 border-light-blue1':
                enforceColorSpaceControl.value === 'CMYK'
            }"
            (click)="enforceColorSpaceControl.setValue('CMYK')"
          >
            CMYK
          </button>
          <button
            class="border border-gray3 rounded-[50px] py-1 px-4"
            [ngClass]="{
              'border-2 border-light-blue1':
                enforceColorSpaceControl.value === 'RGB'
            }"
            (click)="enforceColorSpaceControl.setValue('RGB')"
          >
            RGB
          </button>
        </div>
      </div>
    </div>
    <div
      class="mt-7 pt-8 border-t border-gray3 grid grid-cols-1 lg:grid-cols-9 gap-6"
      [formGroup]="generatedValidations"
      *ngIf="canEnforceResolution"
    >
      <div *ngIf="canEnforceResolution" class="lg:col-span-5">
        <label class="flex items-center gap-[9px]">
          <input
            type="checkbox"
            value=""
            class="sr-only"
            formControlName="validate_resolution"
            id="validate_resolution"
          />
          <span class="custom-checkbox"></span>
          <span class="font-bold text-sm" transloco="Auflösung definieren"
            >Auflösung definieren</span
          >
        </label>
        <div class="w-full flex flex-col mt-4" *ngIf="isValidatingResolution">
          <label
            class="block mb-2 text-xs 2xl:text-sm font-medium text-gray-900 dark:text-white"
            for="resolution"
            transloco="Mindestauflösung in DPI"
            >Mindestauflösung in DPI</label
          >
          <input
            type="number"
            id="resolution"
            formControlName="resolution"
            class="border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-fit font-Satoshi text-15px font-medium text-primary"
            [placeholder]="t('Enter resolution')"
          />
        </div>
      </div>
      <div *ngIf="canEnforceColorSpace" class="lg:col-span-4">
        <label class="flex items-center gap-[9px]">
          <input
            type="checkbox"
            value=""
            class="sr-only"
            formControlName="proof_required"
            id="proof_required"
          />
          <span class="custom-checkbox"></span>
          <span class="font-bold text-sm" transloco="Proof needed"
            >Proof needed</span
          >
        </label>
        <div
          class="w-full flex flex-col mt-4"
          transloco="Check in case a proof needs to be created by agency or producer."
        >
          Check in case a proof needs to be created by agency or producer.
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white rounded-lg px-6 py-5 mt-6" [formGroup]="selectedSection">
    <h3
      transloco="Info-Dokumente und Vorlage Dateien"
      class="text-primary text-lg font-bold mb-4"
    >
      Info-Dokumente und Vorlage Dateien
    </h3>
    <div
      class="flex flex-col py-4 border-gray3"
      *ngFor="
        let infoFile of currentInfoFiles.controls;
        let index = index;
        let isLast = last
      "
      [ngClass]="{ 'border-b': !isLast }"
    >
      <div class="flex items-center gap-3">
        <div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="5" fill="#8A260E" />
            <path
              d="M12.7623 21.476H10.8303V24.5H9.29427V15.728H12.7623C14.5263 15.728 15.6663 16.88 15.6663 18.596C15.6663 20.288 14.5143 21.476 12.7623 21.476ZM12.4623 17.096H10.8303V20.108H12.4383C13.4943 20.108 14.0583 19.544 14.0583 18.584C14.0583 17.624 13.4823 17.096 12.4623 17.096ZM20.1143 24.5H16.9583V15.728H20.0303C22.6223 15.728 24.4103 17.516 24.4103 20.132C24.4103 22.712 22.6583 24.5 20.1143 24.5ZM19.8863 17.144H18.4943V23.084H19.9703C21.7223 23.084 22.7903 21.956 22.7903 20.132C22.7903 18.272 21.6983 17.144 19.8863 17.144ZM30.6411 20.912H27.3771V24.5H25.8411V15.728H31.2651V17.144H27.3771V19.532H30.6411V20.912Z"
              fill="white"
            />
          </svg>
        </div>
        <h4 class="font-bold text-base text-primary">
          {{ infoFile.get("title")?.value }}
        </h4>
      </div>
      <p class="text-15px text-primary mt-2">
        {{ infoFile.get("description")?.value }}
      </p>
      <!--      <a-->
      <!--        *ngIf="!!infoFile.value?.url"-->
      <!--        [href]="sanitizer.bypassSecurityTrustUrl(infoFile.value?.url)"-->
      <!--        target="_blank"-->
      <!--        class="text-blue-500 hover:underline mr-2 text-xs"-->
      <!--        transloco="View File"-->
      <!--      >-->
      <!--        View File-->
      <!--      </a>-->
      <!--      <button (click)="removeInfoFile(index)">-->
      <!--        <svg-->
      <!--          width="24"-->
      <!--          height="24"-->
      <!--          viewBox="0 0 24 24"-->
      <!--          fill="none"-->
      <!--          xmlns="http://www.w3.org/2000/svg"-->
      <!--        >-->
      <!--          <path-->
      <!--            fill-rule="evenodd"-->
      <!--            clip-rule="evenodd"-->
      <!--            d="M21 6H3V9C3.53043 9 4.03914 9.21071 4.41421 9.58579C4.78929 9.96086 5 10.4696 5 11V15C5 17.828 5 19.243 5.879 20.121C6.757 21 8.172 21 11 21H13C15.829 21 17.243 21 18.121 20.121C19.001 19.243 19.001 17.828 19.001 15V11C19.001 10.4696 19.2117 9.96086 19.5868 9.58579C19.9619 9.21071 20.4706 9 21.001 9L21 6ZM10.5 11C10.5 10.7348 10.3946 10.4804 10.2071 10.2929C10.0196 10.1054 9.76522 10 9.5 10C9.23478 10 8.98043 10.1054 8.79289 10.2929C8.60536 10.4804 8.5 10.7348 8.5 11V16C8.5 16.2652 8.60536 16.5196 8.79289 16.7071C8.98043 16.8946 9.23478 17 9.5 17C9.76522 17 10.0196 16.8946 10.2071 16.7071C10.3946 16.5196 10.5 16.2652 10.5 16V11ZM15.5 11C15.5 10.7348 15.3946 10.4804 15.2071 10.2929C15.0196 10.1054 14.7652 10 14.5 10C14.2348 10 13.9804 10.1054 13.7929 10.2929C13.6054 10.4804 13.5 10.7348 13.5 11V16C13.5 16.2652 13.6054 16.5196 13.7929 16.7071C13.9804 16.8946 14.2348 17 14.5 17C14.7652 17 15.0196 16.8946 15.2071 16.7071C15.3946 16.5196 15.5 16.2652 15.5 16V11Z"-->
      <!--            fill="#E92525"-->
      <!--          />-->
      <!--          <path-->
      <!--            d="M10.0684 3.37005C10.1824 3.26405 10.4334 3.17005 10.7834 3.10305C11.185 3.03162 11.5924 2.99714 12.0004 3.00005C12.4404 3.00005 12.8684 3.03605 13.2174 3.10305C13.5674 3.17005 13.8174 3.26405 13.9324 3.37105"-->
      <!--            stroke="#E92525"-->
      <!--            stroke-width="2"-->
      <!--            stroke-linecap="round"-->
      <!--          />-->
      <!--        </svg>-->
      <!--      </button>-->
    </div>
    <div class="mt-4">
      <button
        class="tw-btn-blue py-2"
        transloco="+ Dokument hinzufügen"
        (click)="toggleUploadInfoPDFModal()"
        [disabled]="!canAddInfoFiles()"
      >
        + Dokument hinzufügen
      </button>
    </div>
  </div>
</div>
