import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EditClientModalService } from './edit-client-modal.service';
import { ClientSuccessorPickerModalService } from '../clients-list-view-page/client-successor-picker-modal.service';
import { Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-edit-client-modal',
  templateUrl: './edit-client-modal.component.html',
  styleUrls: ['./edit-client-modal.component.scss'],
})
export class EditClientModalComponent implements OnInit {
  @ViewChild('toggleClientModalBtn') toggleClientModalBtn:
    | ElementRef
    | undefined;
  @ViewChild('showClientModalBtn') showClientModalBtn: ElementRef | undefined;
  @ViewChild('hideClientModalBtn') hideClientModalBtn: ElementRef | undefined;

  @ViewChild('showContactModalBtn') showContactModalBtn: ElementRef | undefined;
  @ViewChild('hideContactModalBtn') hideContactModalBtn: ElementRef | undefined;

  @ViewChild('toggleConfirmCloseModalBtn') toggleConfirmCloseModalBtn:
    | ElementRef
    | undefined;

  closeConfirmModalEvents = new Subject<ModalEvents>();

  constructor(
    public editClientModalService: EditClientModalService,
    private clientSuccessorPickerModalService: ClientSuccessorPickerModalService
  ) {}

  isProducer = false;
  dataCloseModalType?: string;

  ngOnInit(): void {
    this.editClientModalService.modalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
        case 'edit':
          this.isProducer = window.location.pathname.startsWith('/producers');
          this.editClientModalService.modalEvents.next({ type: 'open' });
          break;
      }
    });

    this.editClientModalService.contactModalEvents.subscribe((event) => {
      switch (event.type) {
        case 'new':
        case 'edit':
          this.editClientModalService.modalEvents.next({ type: 'hide' });
          this.editClientModalService.contactModalEvents.next({ type: 'open' });
          break;
        case 'close':
          if (!this.editClientModalService.editNoOpenMode) {
            this.editClientModalService.modalEvents.next({ type: 'show' });
            this.editClientModalService.modalEvents.next({ type: 'open' });
          }
      }
    });

    this.closeConfirmModalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.closeConfirmModalEvents.next({ type: 'close' });
      }
    });
  }

  editContact(id: number) {
    this.editClientModalService.contactModalEvents.next({
      type: 'edit',
      id: id,
    });
  }

  addContact() {
    this.editClientModalService.contactModalEvents.next({
      type: 'new',
    });
  }

  initContactModalClose() {
    if (
      JSON.stringify(this.editClientModalService.contactFormInitialValue) !==
      JSON.stringify(this.editClientModalService.contactForm.value)
    ) {
      this.dataCloseModalType = 'contact';
      this.closeConfirmModalEvents.next({ type: 'open' });
    } else {
      this.editClientModalService.contactModalEvents.next({ type: 'close' });
    }
  }

  initClientModalClose() {
    if (
      JSON.stringify({
        company_name:
          this.editClientModalService.companyNameControl.value || '',
        contacts: this.editClientModalService.contacts,
      }) !== JSON.stringify(this.editClientModalService.clientInitialValue)
    ) {
      this.dataCloseModalType = 'client';
      this.closeConfirmModalEvents.next({ type: 'open' });
    } else {
      this.editClientModalService.modalEvents.next({ type: 'close' });
    }
  }

  handleConfirmCloseClick() {
    if (this.dataCloseModalType === 'contact') {
      this.hideCloseConfirmModal();
      this.editClientModalService.contactModalEvents.next({ type: 'close' });
    } else {
      this.hideCloseConfirmModal();
      this.editClientModalService.modalEvents.next({ type: 'close' });
    }
  }

  hideCloseConfirmModal() {
    this.closeConfirmModalEvents.next({ type: 'close' });
  }

  initDeleteContact(id: number) {
    this.clientSuccessorPickerModalService.modalEvents.next({
      type: 'init',
      client_id: this.editClientModalService.currentClientId!,
      contact_id: id,
    });

    this.editClientModalService.currentContactId = id;
    this.editClientModalService.deleteConfirmType = 'contact';
    this.editClientModalService.deleteConfirmModalEvents.next({ type: 'open' });
  }

  initDeleteClient() {
    this.editClientModalService.deleteConfirmType = 'client';
    this.editClientModalService.deleteConfirmModalEvents.next({ type: 'open' });
  }
}
