<app-accordian-item
  title="Kommentare"
  iconSize="sm"
  [variant]="2"
  *ngIf="requestViewPageService.selectedSection"
>
  <div heading class=" ml-2 bg-gray8 rounded-lg px-2 py-1 text-xs">
    {{ requestViewPageService.selectedSection.section_comments.length }}
  </div>
  <div
    *ngIf="requestViewPageService.selectedSection.section_comments.length === 0"
    class="font-semibold text-13px text-primary opacity-50 leading-20.8px"
    transloco="Es gibt keine kommentare."
  >
    Es gibt keine kommentare.
  </div>
  <div class="flex flex-col gap-4 pt-4" *ngIf="requestViewPageService.selectedSection.section_comments.length">
    <div
      *ngFor="
      let comment of requestViewPageService.selectedSection.section_comments
    "
      class="border border-red3 rounded-2xl bg-red4 px-5 py-4"
    >
      <div class="flex justify-end -mb-3">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2659_8555)">
            <path d="M20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10ZM10 5C9.84201 5.00009 9.68579 5.03327 9.5414 5.09741C9.39701 5.16155 9.26766 5.25522 9.16168 5.37239C9.0557 5.48956 8.97543 5.62763 8.92606 5.77771C8.87668 5.92779 8.85929 6.08654 8.875 6.24375L9.3125 10.6275C9.3272 10.7997 9.406 10.9601 9.53331 11.077C9.66061 11.1939 9.82716 11.2588 10 11.2588C10.1728 11.2588 10.3394 11.1939 10.4667 11.077C10.594 10.9601 10.6728 10.7997 10.6875 10.6275L11.125 6.24375C11.1407 6.08654 11.1233 5.92779 11.0739 5.77771C11.0246 5.62763 10.9443 5.48956 10.8383 5.37239C10.7323 5.25522 10.603 5.16155 10.4586 5.09741C10.3142 5.03327 10.158 5.00009 10 5ZM10.0025 12.5C9.67098 12.5 9.35304 12.6317 9.11862 12.8661C8.8842 13.1005 8.7525 13.4185 8.7525 13.75C8.7525 14.0815 8.8842 14.3995 9.11862 14.6339C9.35304 14.8683 9.67098 15 10.0025 15C10.334 15 10.652 14.8683 10.8864 14.6339C11.1208 14.3995 11.2525 14.0815 11.2525 13.75C11.2525 13.4185 11.1208 13.1005 10.8864 12.8661C10.652 12.6317 10.334 12.5 10.0025 12.5Z" fill="#EF3054"/>
          </g>
          <defs>
            <clipPath id="clip0_2659_8555">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div class="font-semibold text-red5 mb-2">{{comment.title}}</div>
      <p class="mb-2">{{comment.comment}}</p>
      <div class="text-primary opacity-70 flex justify-end">{{formatedDate(comment.updated_at)}}</div>
    </div>
  </div>

</app-accordian-item>
