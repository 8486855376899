<section
  class="flex justify-between flex-wrap gap-4 border-b border-gray5 sticky top-0 pt-3 pb-4 font-Satoshi"
>
  <div class="flex flex-wrap gap-6 items-center">
    <div class="flex flex-col gap-2">
      <h1 class="font-semibold text-2xl 2xl:text-3xl font-Montserrat" transloco="Settings">Settings</h1>
    </div>
  </div>
  <div class="flex flex-wrap gap-4 items-center">
    <button
      class="tw-btn-secondary"
      type="button"
      (click)="handleSubmit()"
      transloco="Save"
    >
      Save
    </button>
  </div>
</section>
<section class="my-6">
  <ul class="gap-3" [formGroup]="customerSettingsForm">
    <li class="flex items-center">
      <label for="enable_producers" class="font-semibold text-lg mr-6"
             transloco="Enable Producers"
        >Enable Producers</label
      >
      <label class="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value=""
          class="sr-only peer"
          formControlName="enable_producers"
          id="enable_producers"
        />
        <div
          class="border border-gray3 p-3 relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange1 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-orange1 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white peer-checked:after:bg-orange1 after:border-gray3 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-white"
        ></div>
      </label>
    </li>
  </ul>
</section>
