import { Injectable } from '@angular/core';
import { AuthService } from './backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private authService: AuthService,
    private translocoService: TranslocoService,
    private toastr: ToastrService
  ) {
    const savedLang = localStorage.getItem('current_language');
    if (savedLang) {
      this.translocoService.setActiveLang(savedLang);
    }
  }

  setLanguage(lang: string) {
    localStorage.setItem('current_language', lang);
    this.translocoService.setActiveLang(lang);
    this.authService.updateLang({ preferred_lang: lang }).subscribe({
      error: (res) => {
        this.toastr.error(
          res.error?.message ||
            this.translocoService.translate(`Something went wrong`)
        );
      },
    });
  }
}
