import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from './client.service';
import { FileType } from './file-type.service';
import {data} from "autoprefixer";

export type AppRequest = {
  id: number;
  public_hash: string;
  request_status_id: number;
  request_status_id_gen?: number;
  request_folder_id?: number;
  status: RequestStatus;
  customer_id: number;
  client: Client;
  client_id: number;
  contact_id: number;
  title: string;
  project_number?: string;
  producer_project_number?: string;
  description: string;
  section_groups?: SectionGroup[];
  sections?: RequestSection[];
  request_comments: RequestComment[];
  due_date?: string;
  created_at: string;
  updated_at: string;
  shared_with_client?: boolean;
  logo?: {
    url: string;
  };
};

export type SectionGroup = {
  id: number;
  title: string;
  request_id: number;
  sections: RequestSection[];
  created_at: string;
  updated_at: string;
};

export type RequestSection = {
  id: number;
  title: string;
  description: string;
  max_file_count: number;
  max_file_size: number;
  expected_files: RequestExpectedFile[];
  info_files: RequestInfoFile[];
  uploaded_files: RequestUploadedFile[];
  file_history: RequestFileHistoryItem[];
  section_comments: RequestSectionComment[];
  created_at: string;
  updated_at: string;
  requirement_title?: string;
  allowed_file_formats?: string;
  color_space?: string;
  dimensions?: string;
  resolution?: string;
  status: number;
  producer_contacts: SectionProducerContact[];
  producer_id?: number;
  new_producer_contacts?: number[];
  expected_file_group?: string;
  proof_file?: {
    is_new?: boolean;
    path?: string;
    preview_url: string;
    thumbnail_url: string;
    url: string;
    file_size: number;
    file_name: string;
    title: string;
  };
};

export type RequestExpectedFile = {
  id: number;
  color_space?: string;
  dimensions_height?: number;
  dimensions_width?: number;
  dimensions_bleed?: number;
  dimensions_unit?: string;
  resolution?: number;
  file_type: FileType;
  file_type_id: number;
  request_section_id: number;
  created_at: string;
  updated_at: string;
  is_custom_type?: boolean;
  proof_required?: boolean;
};

export type RequestInfoFile = {
  id: number;
  title: string;
  description?: string;
  fileType: FileType;
  fileKey?: string;
  request_section_id: number;
  stored_file_id: number;
  url: string;
  created_at: string;
  updated_at: string;
};

export type RequestUploadedFile = {
  id: number;
  created_at: string;
  updated_at: string;
  fileType: FileType;
  request_section_id: number;
  stored_file_id: number;
  thumbnail_url: string;
  preview_url: string;
  is_active?: boolean;
  path: string;
  url: string;
  title: string;
  file_size: number;
  is_draft?: boolean;
  validation_data?: RequestValidationResult;
};

export type RequestFileHistoryItem = {
  timestamp: number;
  action: 'uploaded' | 'removed';
  title: string;
  file_type: {
    id: number;
    name: string;
    icon_svg: string;
  };
  file_size: number;
  url: string;
  user_name?: string;
  current_status_id: number;
};

export type SectionProducerContact = {
  id: number;
  contact_id: number;
  request_section_id: number;
  created_at: string;
  updated_at: string;
};

export type RequestValidationData = {
  valid: boolean;
  message: string;
};

export type RequestValidationResult = {
  color_space?: RequestValidationData;
  dimensions?: RequestValidationData;
  file_size: RequestValidationData;
  file_type: RequestValidationData;
  resolution?: RequestValidationData;
  is_valid: boolean;
};

export type RequestComment = {
  first_name: string;
  last_name: string;
  content: string;
  created_at: string;
  updated_at: string;
};

export type RequestSectionComment = {
  id?: number;
  request_section_id: number;
  title: string;
  comment: string;
  notified: boolean;
  created_at: string;
  updated_at: string;
};

export type RequestStatus = {
  id: number;
  title: string;
};

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private http: HttpClient) {}

  getRequests(params: any) {
    let updatedParams: any = {};
    // remove undefined key&value pairs from query
    Object.entries(params)
      .filter(([k, v]) => v !== undefined)
      .forEach(([k, v]) => {
        updatedParams[k] = v;
      });

    return this.http.get<{
      success: boolean;
      total: number;
      data: AppRequest[];
    }>('/api/requests/', { params: updatedParams });
  }

  getRequestById(id: number) {
    return this.http.get<{ success: boolean; data: AppRequest }>(
      `/api/requests/${id}`
    );
  }

  getRequestByPublicHash(hash: string) {
    return this.http.get<{ success: boolean; data: AppRequest }>(
      `/api/requests/hash/${hash}`
    );
  }

  createRequest(data: any) {
    return this.http.post<{ success: boolean; data: AppRequest }>(
      '/api/requests',
      data
    );
  }

  updateRequest(id: number, data: FormData) {
    data.append('_method', 'PUT');
    return this.http.post<{ success: boolean; data: AppRequest }>(
      `/api/requests/${id}`,
      data
    );
  }

  updateRequestStatus(
    id: number,
    body: {
      status_id: number;
      producer_id?: number;
      message?: string;
      contact_ids?: number[];
      producer_hash?: string;
    }
  ) {
    return this.http.put<{ success: true; message: string }>(
      `/api/requests/${id}/update-status`,
      body
    );
  }

  validateRequestFile(data: {
    file_path: string;
    request_hash: string;
    section_id: number;
  }) {
    return this.http.post<{ success: boolean; data: RequestValidationResult }>(
      '/api/requests/validate_file',
      data
    );
  }

  cacheRequestFiles(data: {
    request_hash: string;
    files: {
      section_id: string;
      file_path: string;
    }[];
  }) {
    return this.http.post('/api/requests/cache_files', data);
  }

  submitRequestFiles(data: {
    request_hash: string;
    files: {
      section_id: string;
      file_path: string;
    }[];
  }) {
    return this.http.post('/api/requests/submit_files', data);
  }

  submitRequestFilesV4(data: {
    request_hash: string;
    sections?: {
      id: number;
      status: number;
      section_comments: { title: string; comment: string }[];
    }[];
  }) {
    return this.http.post('/api/requests/submit_files_v4', data);
  }

  removeFile(data: {
    request_hash: string;
    section_id: number;
    uploaded_file_id: number;
  }) {
    return this.http.post('/api/requests/remove_file', data);
  }

  addComment(data: {
    request_hash: string;
    first_name: string;
    last_name: string;
    content: string;
  }) {
    return this.http.post('/api/requests/comment', data);
  }

  sendEmail(data: { request_id: number; message: string; recipients: string }) {
    return this.http.post('/api/requests/send-email', data);
  }

  getRequestStatusList() {
    return this.http.get<{
      success: true;
      data: RequestStatus[];
    }>('/api/request_statuses');
  }

  submitRequestAsAgency(
    publicHash: string,
    data: {
      sections: {
        id: number;
        status: number;
        section_comments: { title: string; comment: string }[];
        producer_id?: number;
        producer_contacts?: number[];
        proof_file_path?: string;
      }[];
    }
  ) {
    return this.http.post(`/api/requests/submit-as-agency/${publicHash}`, data);
  }

  submitRequestSectionAsAgencyV5(
    publicHash: string,
    data: {
        id: number;
        status: number;
        section_comments: { title: string; comment: string }[];
        producer_id?: number;
        producer_contacts?: number[];
        proof_file_path?: string;
      }
  ) {
    return this.http.post(`/api/requests/submit-as-agency-v5/${publicHash}`, data);
  }

  submitRequestAsProducer(
    publicHash: string,
    data: {
      producer_hash: string;
      sections: {
        id: number;
        status: number;
        section_comments: { title: string; comment: string }[];
      }[];
    }
  ) {
    return this.http.post(
      `/api/requests/submit-as-producer/${publicHash}`,
      data
    );
  }

  submitRequestSectionAsProducerV5(
    publicHash: string,
    data: {
      producer_hash: string;
      id: number;
      status: number;
      section_comments: { title: string; comment: string }[];
      proof_file_path?: string;
    }
  ) {
    return this.http.post(`/api/requests/submit-as-producer-v5/${publicHash}`, data);
  }

  submitRequestSectionAsClientV5(
    publicHash: string,
    data: {
      id: number;
      status: number;
      section_comments: { title: string; comment: string }[];
    }
  ) {
    return this.http.post(`/api/requests/submit-as-client-v5/${publicHash}`, data);
  }

  deleteRequest(id: number) {
    return this.http.delete(`/api/requests/${id}`);
  }
}
