<app-custom-modal [modalEvents]="modalService.modalEvents" classes="max-w-3xl">
  <h1 header class="text-xl font-semibold" transloco="Edit Section Collection">
    Edit Section Collection
  </h1>
  <div content-body class="w-full" *transloco="let t">
    <div>
      <div
        class="flex flex-col gap-2 mb-12"
        *ngIf="modalService.sectionCollection"
      >
        <label
          class="font-semibold text-sm"
          for="section-collection-title"
          transloco="Collection Title"
          >Collection Title</label
        >
        <input
          type="text"
          [formControl]="$any(modalService.sectionCollection?.get('title'))"
          id="section-collection-title"
          [placeholder]="t('Input section collection title...')"
          class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1 w-full font-semibold text-sm"
          [class.border-red-400]="
            modalService.sectionCollection?.get('title')?.touched &&
            modalService.sectionCollection?.get('title')?.invalid
          "
        />
        <div
          class="text-red-400"
          *ngIf="
            modalService.sectionCollection?.get('title')?.touched &&
            modalService.sectionCollection?.get('title')?.invalid
          "
        >
          <small
            *ngIf="
              modalService.sectionCollection?.get('title')?.errors?.required
            "
            transloco="required"
          >
            required
          </small>
          <small
            *ngIf="
              modalService.sectionCollection?.get('title')?.errors?.maxlength
            "
            transloco="Must be less than 255 characters"
          >
            Must be less than 255 characters
          </small>
        </div>
      </div>
    </div>
  </div>
  <div footer class="flex justify-between w-full">
    <button
      class="text-red-500 bg-transparent border-red-500 border rounded-lg focus:outline-1 outline-orange1 font-semibold py-2 px-4 2xl:py-3 hover:text-white hover:bg-red-500 duration-200 transition-all disabled:opacity-60 disabled:pointer-events-none min-w-[150px]"
      transloco="Delete"
      (click)="modalService.handleDelete()"
    >
      Delete
    </button>
    <button
      class="tw-btn-secondary min-w-[150px]"
      transloco="OK"
      (click)="modalService.handleClose()"
    >
      OK
    </button>
  </div>
</app-custom-modal>
