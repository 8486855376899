import { Component, Input } from '@angular/core';
import { AppRequest } from '../../backend-services/request.service';
import { RequestViewPageService } from '../request-view-page.service';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-request-view-page-header',
  templateUrl: './request-view-page-header.component.html',
  styleUrls: ['./request-view-page-header.component.scss'],
})
export class RequestViewPageHeaderComponent {
  @Input() requestData?: AppRequest;

  collapsed: boolean = true;

  constructor(
    public requestViewPageService: RequestViewPageService,
    private authService: AuthService,
    private translocoService: TranslocoService
  ) {}

  /**
   * We don't need this anymore since header is not sticky
   */
  // @HostListener('window:scroll', []) onWindowScroll() {
  //   // do some stuff here when the window is scrolled
  //   const verticalOffset =
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop ||
  //     0;
  //
  //   const collapseHeight = 200;
  //   if (verticalOffset > collapseHeight && !this.collapsed) {
  //     this.collapsedTimeStamp = Date.now();
  //     this.collapsed = true;
  //   } else if (verticalOffset <= 0 && this.collapsed) {
  //     if (
  //       this.collapsedTimeStamp &&
  //       Date.now() - this.collapsedTimeStamp < 400
  //     ) {
  //       return;
  //     }
  //
  //     this.collapsed = false;
  //     this.collapsedTimeStamp = Date.now();
  //   }
  // }

  get topNotificationBanner() {
    const data: { show: boolean; message?: string; bgClass?: string } = {
      show: false,
      bgClass: 'bg-red3',
    };

    // only show to clients
    if (
      this.requestViewPageService.producerHash ||
      this.authService.isAuthenticated.value
    ) {
      return data;
    }

    if (this.requestViewPageService.requestData?.request_status_id === 1) {
      if (this.requestViewPageService.globalSectionStatusCounter[102]) {
        // data check error message
        data.show = true;
        data.message =
          this.requestViewPageService.globalSectionStatusCounter[102] +
          this.translocoService.translate(
            `Datei entspricht nicht den Datencheck-Anforderungen. Bitte korrigieren Sie die Fehler bevor Sie Ihre Daten an die Agentur übermitteln können!`
          );
      } else if (
        this.requestViewPageService.globalSectionStatusCounter[202] ||
        this.requestViewPageService.globalSectionStatusCounter[302]
      ) {
        // rejected by agent or producer
        data.show = true;
        const count =
          (this.requestViewPageService.globalSectionStatusCounter[202] || 0) +
          (this.requestViewPageService.globalSectionStatusCounter[302] || 0);

        data.message = this.translocoService.translate(
          `Es wurde {{count]} Fehler gefunden. Bitte überprüfen und korrigieren Sie Ihre Daten.`,
          { count }
        );
      }
    } else if (
      this.requestViewPageService.requestData?.request_status_id === 2
    ) {
      data.show = true;
      data.message = this.translocoService.translate(
        `Ihre Daten werden von der Agentur geprüft. Eine Änderung ist im Moment nicht möglich!`
      );
      data.bgClass = 'bg-light-blue2';
    } else if (
      this.requestViewPageService.requestData?.request_status_id === 4
    ) {
      data.show = true;
      data.message = this.translocoService.translate(
        `Ihre Daten wurden von der Agentur geprüft und freigegeben. Eine Änderung ist nicht mehr möglich!`
      );
      data.bgClass = 'bg-green2';
    }

    return data;
  }

  get successSectionsCount() {
    let total = 0;
    total += this.requestViewPageService.globalSectionStatusCounter[4] || 0;
    total += this.requestViewPageService.globalSectionStatusCounter[401] || 0;

    return total;
  }

  get errorSectionsCount() {
    let total = 0;
    total += this.requestViewPageService.globalSectionStatusCounter[102] || 0;
    total += this.requestViewPageService.globalSectionStatusCounter[202] || 0;
    total += this.requestViewPageService.globalSectionStatusCounter[302] || 0;

    return total;
  }

  get proofFileSectionsCount() {
    let total = 0;
    total += this.requestViewPageService.globalSectionStatusCounter[203] || 0;
    total += this.requestViewPageService.globalSectionStatusCounter[303] || 0;

    return total;
  }

  get canCompleteRequest() {
    if (!this.requestViewPageService.isAgency) {
      return false;
    }

    let canComplete = true;
    this.requestViewPageService.globalSectionList.forEach((item) => {
      if(item.section.status !== 206 && item.section.status !== 4 && item.section.status === 401) {
        canComplete = false;
      }
    })

    return canComplete;
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  completeRequest() {

  }
}
