import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client, ClientService } from '../../backend-services/client.service';
import { FormControl } from '@angular/forms';
import { EditClientModalService } from '../edit-client-modal/edit-client-modal.service';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { EditRequestModalService } from 'src/app/request-management/edit-request-modal/edit-request-modal.service';
import { ClientSuccessorPickerModalService } from './client-successor-picker-modal.service';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';

@Component({
  selector: 'app-clients-list-view-page',
  templateUrl: './clients-list-view-page.component.html',
  styleUrls: ['./clients-list-view-page.component.scss'],
})
export class ClientsListViewPageComponent implements OnDestroy {
  queryParams: any = {};
  isProducer = false;

  private subscriptions: any[] = [];

  confirmDeleteModalEvents = new Subject<ModalEvents>();

  constructor(
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    public editClientModalService: EditClientModalService,
    private editRequestModalService: EditRequestModalService,
    public clientSuccessorPickerModalService: ClientSuccessorPickerModalService
  ) {
    this.isProducer = window.location.pathname.startsWith('/producers');

    this.subscriptions.push(
      this.route.queryParamMap.pipe(debounceTime(10)).subscribe((params) => {
        let queryParams: Record<string, string | null> = {};
        params.keys.forEach((k) => {
          queryParams[k] = params.get(k);
        });

        this.queryParams = queryParams;

        if (this.isProducer) {
          this.queryParams.is_producer = true;
        }

        this.searchControl.setValue(this.queryParams.search);

        this.fetchClients();
      })
    );

    this.subscriptions.push(
      this.editClientModalService.modalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchClients();
        }
      })
    );

    this.subscriptions.push(
      this.editClientModalService.contactModalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchClients();
        }
      })
    );

    this.subscriptions.push(
      this.editRequestModalService.modalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchClients();
        }
      })
    );

    this.subscriptions.push(
      this.clientSuccessorPickerModalService.modalEvents.subscribe((ev) => {
        if (ev.type === 'close') {
          this.fetchClients();
        }
      })
    );

    // listen to changes in the search control
    this.subscriptions.push(
      this.searchControl.valueChanges
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((value) => {
          if (value) {
            this.queryParams.search = value;
          } else {
            this.queryParams.search = undefined;
          }

          this.handleQueryParamChange();
        })
    );

    this.confirmDeleteModalEvents.subscribe((ev) => {
      if (ev.type === 'init-close') {
        this.closeDeleteConfirmModal();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  clients: Client[] = [];
  totalClientsCount: number = 0;
  expandedClientId: number | null = null;

  searchControl = new FormControl('');

  fetchClients() {
    // handle edge cases with search value
    if (this.queryParams.search === undefined) {
      delete this.queryParams.search;
    }

    this.clientService.getClients(this.queryParams).subscribe((c) => {
      this.clients = c.data;
      this.totalClientsCount = c.total;
    });
  }

  updateExpandedClientId(id: number) {
    if (this.expandedClientId !== id) {
      this.expandedClientId = id;
    } else {
      this.expandedClientId = null;
    }
  }

  addNewClient() {
    this.editClientModalService.modalEvents.next({
      type: 'new',
    });
  }

  editClient(clientId: number) {
    this.editClientModalService.modalEvents.next({
      type: 'edit',
      id: clientId,
    });
  }

  doSearch() {
    if (this.searchControl.value) {
      this.queryParams.search = this.searchControl.value;
    } else {
      this.queryParams.search = undefined;
    }

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  createNewRequestForClient(clientId: number) {
    this.router.navigate(['requests/new'], {
      queryParams: { client_id: clientId },
    });
  }

  editContact(id: number) {
    this.editClientModalService.modalEvents.next({
      type: 'edit-no-open',
      id: this.expandedClientId!,
    });
    this.editClientModalService.contactModalEvents.next({
      type: 'edit',
      id: id,
    });
  }

  initDeleteContact(id: number, clientId: number) {
    this.clientSuccessorPickerModalService.modalEvents.next({
      type: 'init',
      client_id: clientId,
      contact_id: id,
    });

    this.confirmDeleteModalEvents.next({ type: 'open' });
  }

  openContactSuccessorSelector() {
    this.clientSuccessorPickerModalService.modalEvents.next({ type: 'open' });
    this.closeDeleteConfirmModal();
  }

  closeDeleteConfirmModal() {
    this.confirmDeleteModalEvents.next({ type: 'close' });
  }
}
