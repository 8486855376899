<div class="bg-gray10 min-h-screen flex flex-col">
  <app-nav-bar></app-nav-bar>
  <app-side-drawer></app-side-drawer>

  <div class="container flex-grow-1 mx-auto mt-8 p-3 2xl:p-4 content-container">
    <app-edit-client-modal></app-edit-client-modal>
    <!--  <app-edit-request-modal></app-edit-request-modal>-->
    <app-send-request-modal></app-send-request-modal>
    <app-create-folder-modal></app-create-folder-modal>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
