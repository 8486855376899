import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export type FileType = {
  id?: number;
  name: string;
  description?: string;
  mime_type?: string;
  icon_svg?: string;
  can_enforce_color_space?: string;
  can_enforce_resolution?: string;
  can_enforce_dimensions?: string;
  group_name: string;
  is_custom_type: boolean;
  can_request_proof: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FileTypeService {
  constructor(private http: HttpClient) {
    this.getFileTypes({}).subscribe((res) => {
      this.localFileTypes = res.data;
    });
  }

  localFileTypes: FileType[] = [];

  getFileTypes(params: any) {
    return this.http.get<{ success: boolean; total: number; data: FileType[] }>(
      '/api/file_types/',
      { params }
    );
  }

  getFileTypeById(id: number) {
    return this.http.get<{ success: boolean; data: FileType }>(
      `/api/file_types/${id}`
    );
  }

  getLocalFileTypeById(id: number) {
    return this.localFileTypes.find((ft) => ft.id == id);
  }
}
