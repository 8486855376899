<footer
  [ngClass]="{
    'border-t': !isMinimal
  }"
  class="bg-primary text-white border-gray-200font-Satoshi z-20 w-full shadow flex-shrink-0"
>
  <div class="py-5 w-full container mx-auto grid grid-cols-3">
    <div class="flex justify-start items-center opacity-[0.8]">
      <div class="text-sm">
        <a href="https://rentation.net/impressum.html" transloco="Impressum"
          >Impressum</a
        >
      </div>
      <div class="text-sm mx-4">|</div>
      <div class="text-sm">
        <a href="https://rentation.net/doc/rentation_agb.pdf" transloco="AGB"
          >AGB</a
        >
      </div>
      <div class="text-sm mx-4">|</div>
      <div class="text-sm">
        <a
          href="https://rentation.net/doc/rentation_datenschutz.pdf"
          transloco="Datenschutz"
          >Datenschutz</a
        >
      </div>
    </div>
    <div class="flex justify-center items-center">
      <img src="/assets/imgs/logo.svg" alt="Logo" />
    </div>
    <div class="flex justify-end items-center">
      <a
        href="https://forms.clickup.com/9015142231/f/8cng9uq-18075/V88G8K4HE4GIX5BZ81"
        target="_blank"
        rel="noopener"
        class="tw-btn-blue min-w-[130px] text-center"
        transloco="Support"
      >
        Support
      </a>
    </div>
  </div>
</footer>
