import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestViewPageService } from '../request-view-page.service';
import { RequestService } from '../../backend-services/request.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SectionApprovalModalService {
  modalEvents: Subject<
    | {
        type: 'open';
      }
    | { type: 'close' }
  > = new Subject();

  constructor(
    private requestViewPageService: RequestViewPageService,
    private requestService: RequestService,
    private translocoService: TranslocoService,
    private toastr: ToastrService
  ) {}

  approveCurrentSection() {
    const sectionGroupIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionIndex;

    if (
      sectionGroupIndex !== undefined &&
      sectionIndex !== undefined &&
      this.requestViewPageService.requestData &&
      this.requestViewPageService.requestData.section_groups
    ) {
      const currentSection =
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex];

      if (!!this.requestViewPageService.producerHash) {
        if (currentSection.status === 303) {
          currentSection.status = 203;
        } else {
          currentSection.status = 401;
        }

        this.requestService
          .submitRequestSectionAsProducerV5(
            this.requestViewPageService.requestPublicHash,
            {
              producer_hash: this.requestViewPageService.producerHash,
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                  this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });
      } else if (this.requestViewPageService.isAgency) {
        // if status is proof uploaded > send to client
        if (currentSection.status === 203) {
          currentSection.status = 106;
        } else {
          currentSection.status = 4;
        }

        this.requestService
          .submitRequestSectionAsAgencyV5(
            this.requestViewPageService.requestPublicHash,
            {
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                  this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });
      } else if (currentSection.status === 106) {
        currentSection.status = 206;
        this.requestService
          .submitRequestSectionAsClientV5(
            this.requestViewPageService.requestPublicHash,
            {
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                  this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });
      }

      this.requestViewPageService.handleRequestDataChange();
    }

    this.modalEvents.next({ type: 'close' });
  }
}
