import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: [
    './login.component.scss',
  ],
})
export class LoginComponent {
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  hasLoginError = false;
  formIsSubmitting = false;

  get emailControl() {
    return this.loginForm.get('email') as AbstractControl;
  }

  get passwordControl() {
    return this.loginForm.get('password') as AbstractControl;
  }

  constructor(private authService: AuthService, private router: Router) {
    // redirect to dashboard if logged in
    this.authService.isAuthenticated.subscribe((res) => {
      if (res) {
        this.router.navigate(['/']);
      }
    });
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.hasLoginError = false;

    if (this.loginForm.valid) {
      this.formIsSubmitting = true;
      this.authService.login(this.loginForm.value).subscribe({
        error: () => {
          this.hasLoginError = true;
          this.formIsSubmitting = false;
        },
        next: () => {
          this.formIsSubmitting = false;
        },
      });
    }
  }
}
