import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordian-item',
  templateUrl: './accordian-item.component.html',
  styleUrls: ['./accordian-item.component.scss'],
})
export class AccordianItemComponent {
  @Input() title!: string;
  @Input() iconSize?: 'normal' | 'sm' = 'normal';
  @Input() variant?: number = 1;

  isExpanded = false;

  constructor() {}

  toggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }
}
