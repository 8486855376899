<app-custom-modal
  [modalEvents]="infoUploadModalService.modalEvents"
  classes="max-w-2xl font-Satoshi"
  *transloco="let t"
  maxHeight="700px"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Add Info PDFs"
  >
    Add Info PDFs
  </h3>
  <div
    content-body
    class="flex flex-col my-4 gap-4"
    [formGroup]="infoUploadModalService.infoFileForm"
  >
    <label class="font-semibold text-lg" for="info-file-title" transloco="Title"
      >Title</label
    >
    <input
      id="info-file-title"
      [placeholder]="t('Input title...')"
      formControlName="title"
      type="text"
      class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1"
      [class.border-red-400]="
        infoUploadModalService.infoFileForm.get('title')?.touched &&
        infoUploadModalService.infoFileForm.get('title')?.invalid
      "
    />
    <div
      class="text-red-400"
      *ngIf="
        infoUploadModalService.infoFileForm.get('title')?.touched &&
        infoUploadModalService.infoFileForm.get('title')?.invalid
      "
    >
      <small
        *ngIf="
          infoUploadModalService.infoFileForm.get('title')?.errors?.required
        "
        transloco="required"
      >
        required
      </small>
      <small
        *ngIf="
          infoUploadModalService.infoFileForm.get('title')?.errors?.maxlength
        "
        transloco="Must be less than 255 characters"
      >
        Must be less than 255 characters
      </small>
    </div>
    <label
      class="font-semibold text-lg mt-4"
      for="info-file-description"
      transloco="Description"
      >Description</label
    >
    <textarea
      id="info-file-description"
      [placeholder]="t('Input description...')"
      formControlName="description"
      type="text"
      rows="4"
      class="rounded-lg border border-gray3 focus:ring-black focus:border-darkBlue1"
      [class.border-red-400]="
        infoUploadModalService.infoFileForm.get('description')?.touched &&
        infoUploadModalService.infoFileForm.get('description')?.invalid
      "
    ></textarea>
    <div
      class="text-red-400"
      *ngIf="
        infoUploadModalService.infoFileForm.get('description')?.touched &&
        infoUploadModalService.infoFileForm.get('description')?.invalid
      "
    >
      <small
        *ngIf="
          infoUploadModalService.infoFileForm.get('description')?.errors
            ?.required
        "
        transloco="required"
      >
        required
      </small>
      <small
        *ngIf="
          infoUploadModalService.infoFileForm.get('description')?.errors
            ?.maxlength
        "
        transloco="Must be less than 1000 characters"
      >
        Must be less than 1000 characters
      </small>
    </div>
    <label
      class="font-semibold text-lg mt-4"
      for="dropzone-info-file"
      transloco="Upload"
    >
      Upload
    </label>
    <div
      class="flex items-center justify-center w-full"
      *ngIf="
        !infoUploadModalService.infoFileForm.get('uploaded_file_blob')?.value
      "
    >
      <label
        for="dropzone-info-file"
        class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-100 duration-200"
      >
        <div class="flex flex-col items-center justify-center pt-5 pb-6">
          <svg
            width="78"
            height="78"
            viewBox="0 0 78 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="64"
              height="64"
              rx="32"
              transform="matrix(1 0 0 -1 7 71)"
              fill="#F6F8FA"
            />
            <rect
              width="76"
              height="76"
              rx="38"
              transform="matrix(1 0 0 -1 1 77)"
              stroke="#DDE5EF"
              stroke-dasharray="2 2"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M38.9994 42.7501C39.1983 42.7501 39.3891 42.6711 39.5297 42.5304C39.6704 42.3897 39.7494 42.199 39.7494 42.0001V31.0271L41.4294 32.9881C41.5588 33.1392 41.743 33.2328 41.9414 33.2482C42.1398 33.2636 42.3362 33.1995 42.4874 33.0701C42.6386 32.9406 42.7321 32.7565 42.7475 32.5581C42.7629 32.3596 42.6988 32.1632 42.5694 32.0121L39.5694 28.5121C39.499 28.4297 39.4116 28.3636 39.3132 28.3183C39.2148 28.273 39.1077 28.2495 38.9994 28.2495C38.8911 28.2495 38.784 28.273 38.6856 28.3183C38.5872 28.3636 38.4998 28.4297 38.4294 28.5121L35.4294 32.0121C35.3653 32.0869 35.3166 32.1737 35.286 32.2673C35.2555 32.361 35.2437 32.4598 35.2513 32.5581C35.2589 32.6563 35.2858 32.7521 35.3304 32.8399C35.375 32.9278 35.4365 33.006 35.5114 33.0701C35.5862 33.1342 35.673 33.1829 35.7667 33.2134C35.8603 33.244 35.9591 33.2558 36.0574 33.2482C36.1556 33.2406 36.2514 33.2137 36.3392 33.1691C36.4271 33.1244 36.5053 33.0629 36.5694 32.9881L38.2494 31.0281V42.0001C38.2494 42.4141 38.5854 42.7501 38.9994 42.7501Z"
              fill="black"
            />
            <path
              d="M43 36C42.298 36 41.947 36 41.694 36.169C41.5852 36.2418 41.4918 36.3352 41.419 36.444C41.25 36.697 41.25 37.048 41.25 37.75V42C41.25 42.5967 41.0129 43.169 40.591 43.591C40.169 44.0129 39.5967 44.25 39 44.25C38.4033 44.25 37.831 44.0129 37.409 43.591C36.9871 43.169 36.75 42.5967 36.75 42V37.75C36.75 37.048 36.75 36.697 36.581 36.444C36.5082 36.3352 36.4148 36.2418 36.306 36.169C36.053 36 35.702 36 35 36C32.172 36 30.757 36 29.879 36.879C29 37.757 29 39.17 29 41.999V42.999C29 45.829 29 47.242 29.879 48.121C30.757 49 32.172 49 35 49H43C45.828 49 47.243 49 48.121 48.121C49 47.242 49 45.828 49 43V42C49 39.171 49 37.757 48.121 36.879C47.243 36 45.828 36 43 36Z"
              fill="black"
            />
          </svg>

          <p class="mb-2 text-xs 2xl:text-sm text-gray2" transloco="class">
            <span class="font-semibold text-semantic-primary-700"
              >Click to upload</span
            >
            or drag and drop the info pdf
          </p>
          <p class="text-xs 2xl:text-sm text-semantic-primary-700">
            PDF (MAX. 100MB)
          </p>
        </div>
        <input
          id="dropzone-info-file"
          type="file"
          accept="application/pdf"
          formControlName="file"
          (change)="infoUploadModalService.onFileChange($event)"
          class="hidden"
        />
      </label>
    </div>
    <div
      class="flex items-center gap-4 my-4"
      *ngIf="
        infoUploadModalService.infoFileForm.get('uploaded_file_blob')?.value
      "
    >
      <div
        class="w-[72px] h-[72px] flex justify-center items-center border border-gray3 rounded-lg"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="40" height="40" rx="5" fill="#8A260E" />
          <path
            d="M12.7623 21.476H10.8303V24.5H9.29427V15.728H12.7623C14.5263 15.728 15.6663 16.88 15.6663 18.596C15.6663 20.288 14.5143 21.476 12.7623 21.476ZM12.4623 17.096H10.8303V20.108H12.4383C13.4943 20.108 14.0583 19.544 14.0583 18.584C14.0583 17.624 13.4823 17.096 12.4623 17.096ZM20.1143 24.5H16.9583V15.728H20.0303C22.6223 15.728 24.4103 17.516 24.4103 20.132C24.4103 22.712 22.6583 24.5 20.1143 24.5ZM19.8863 17.144H18.4943V23.084H19.9703C21.7223 23.084 22.7903 21.956 22.7903 20.132C22.7903 18.272 21.6983 17.144 19.8863 17.144ZM30.6411 20.912H27.3771V24.5H25.8411V15.728H31.2651V17.144H27.3771V19.532H30.6411V20.912Z"
            fill="white"
          />
        </svg>
      </div>
      <button
        type="button"
        (click)="
          infoUploadModalService.infoFileForm.get('uploaded_file_blob')?.reset()
        "
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.82692 6L9.59615 12M6.40385 12L6.17308 6M12.8184 3.86038C13.0464 3.89481 13.2736 3.93165 13.5 3.97086M12.8184 3.86038L12.1065 13.115C12.0464 13.8965 11.3948 14.5 10.611 14.5H5.38905C4.60524 14.5 3.95358 13.8965 3.89346 13.115L3.18157 3.86038M12.8184 3.86038C12.0542 3.74496 11.281 3.65657 10.5 3.59622M2.5 3.97086C2.72638 3.93165 2.95358 3.89481 3.18157 3.86038M3.18157 3.86038C3.94585 3.74496 4.719 3.65657 5.5 3.59622M10.5 3.59622V2.98546C10.5 2.19922 9.8929 1.54282 9.10706 1.51768C8.73948 1.50592 8.37043 1.5 8 1.5C7.62957 1.5 7.26052 1.50592 6.89294 1.51768C6.1071 1.54282 5.5 2.19922 5.5 2.98546V3.59622M10.5 3.59622C9.67504 3.53247 8.84131 3.5 8 3.5C7.15869 3.5 6.32496 3.53247 5.5 3.59622"
            stroke="#97B7C4"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
  <div footer class="w-full flex items-center justify-end gap-4 flex-wrap">
    <button
      class="transition-all duration-200 text-primary bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary rounded-lg border border-gray-200 text-xs 2xl:text-sm font-medium px-4 py-2 2xl:px-6 2xl:py-4 hover:text-gray-900"
      type="button"
      (click)="closeInfoUploadPdfModal()"
      [disabled]="editRequestModalService.requestFormGroup.disabled"
      transloco="Cancel"
    >
      Cancel
    </button>
    <button
      class="duration-200 transition-all text-white bg-primary hover:bg-darkBlue1 focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-xs 2xl:text-sm px-4 py-2 2xl:px-6 2xl:py-4 text-center"
      type="button"
      (click)="infoUploadModalService.handleCreateInfoFile()"
      [disabled]="editRequestModalService.requestFormGroup.disabled"
      transloco=" Upload"
    >
      Upload
    </button>
  </div>
</app-custom-modal>
