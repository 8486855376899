import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EditRequestModalService } from '../../edit-request-modal/edit-request-modal.service';
import { InfoUploadModalService } from './info-upload-modal.service';

@Component({
  selector: 'app-info-upload-modal',
  templateUrl: './info-upload-modal.component.html',
  styleUrls: ['./info-upload-modal.component.scss'],
})
export class InfoUploadModalComponent {
  constructor(
    public editRequestModalService: EditRequestModalService,
    public infoUploadModalService: InfoUploadModalService
  ) {}

  closeInfoUploadPdfModal() {
    this.infoUploadModalService.modalEvents.next({ type: 'close' });
  }
}
