import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SectionApprovalModalService } from './section-approval-modal.service';

@Component({
  selector: 'app-section-approval-modal',
  templateUrl: './section-approval-modal.component.html',
  styleUrls: ['./section-approval-modal.component.scss'],
})
export class SectionApprovalModalComponent {

  constructor(
    public sectionApprovalModalService: SectionApprovalModalService
  ) {
  }

  closeModal() {
    this.sectionApprovalModalService.modalEvents.next({type: 'close'})
  }
}
