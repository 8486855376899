import { Component } from '@angular/core';
import { SectionRejectionModalService } from './section-rejection-modal.service';

@Component({
  selector: 'app-section-rejection-modal',
  templateUrl: './section-rejection-modal.component.html',
  styleUrls: ['./section-rejection-modal.component.scss'],
})
export class SectionRejectionModalComponent {
  constructor(
    public sectionRejectionModalService: SectionRejectionModalService
  ) {}

  closeModal() {
    this.sectionRejectionModalService.modalEvents.next({ type: 'close' });
  }
}
