<nav class="z-20 w-full bg-primary">
  <div class="flex items-center p-3 lg:p-4 justify-between container mx-auto">
    <a href="#" class="flex items-center space-x-3 w-[143px]">
      <img src="assets/imgs/ahoidata_white.svg" alt="AHOIDATA Logo" />
    </a>
    <div class="hidden lg:block" id="navbar-solid-bg">
      <div
        class="flex flex-col lg:flex-row justify-between items-center w-full"
      >
        <ul
          class="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 lg:space-x-4 lg:flex-row lg:mt-0 lg:border-0 lg:bg-transparent w-full gap-x-12"
        >
          <li>
            <a
              routerLink="/requests"
              [ngClass]="{
                'border-light-blue5 text-white': isActive('/requests'),
                'border-transparent': !isActive('/requests')
              }"
              class="flex px-1 py-2 gap-2 font-bold uppercase border-b-2 text-gray1 border-transparent"
            >
              <span transloco="Requests">Requests</span>
            </a>
          </li>
          <li>
            <a
              routerLink="/clients"
              [ngClass]="{
                'border-light-blue5 text-white': isActive('/clients'),
                'border-transparent': !isActive('/clients')
              }"
              class="flex px-1 py-2 gap-2 font-bold uppercase border-b-2 text-gray1 border-transparent"
            >
              <span transloco="Clients">Clients</span>
            </a>
          </li>
          <li
            *ngIf="
              customerSettingsService.currentSettings.value.enable_producers
            "
          >
            <a
              routerLink="/producers"
              [ngClass]="{
                'border-light-blue5 text-white': isActive('/producers'),
                'border-transparent': !isActive('/producers')
              }"
              class="flex px-1 py-2 gap-2 font-bold uppercase border-b-2 text-gray1"
            >
              <span transloco="Producers">Producers</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <ul
      class="flex font-medium rounded-lg space-x-2 flex-row bg-transparent items-center w-fit gap-4"
    >
      <li class="h-[24px]">
        <button title="notifications">
          <img src="/assets/imgs/icons/heroicons-outline/bell.svg" alt="" />
        </button>
      </li>
      <li>
        <button
          type="button"
          data-drawer-target="side-drawer"
          data-drawer-show="side-drawer"
          data-drawer-placement="right"
          aria-controls="side-drawer"
          class="w-10 h-10 bg-gray15 flex justify-center items-center rounded-full"
        >
          <img src="/assets/imgs/icons/heroicons-outline/user.svg" alt="" />
        </button>
      </li>
    </ul>
  </div>
</nav>
