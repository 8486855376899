import { Component } from '@angular/core';
import { UploadProofService } from './upload-proof.service';

@Component({
  selector: 'app-upload-proof-modal',
  templateUrl: './upload-proof-modal.component.html',
  styleUrls: ['./upload-proof-modal.component.scss'],
})
export class UploadProofModalComponent {
  constructor(public uploadProofService: UploadProofService) {}

  closeModal() {
    this.uploadProofService.modalEvents.next({ type: 'close' });
  }

  onFileChange(event: any) {
    const newFile = event.target.files[0];

    this.uploadProofService.modalEvents.next({
      type: 'upload-file',
      file: newFile,
    });
  }
}
