<div
  class="min-h-screen w-full bg-primaryV2 flex justify-center"
  *transloco="let t"
>
  <div class="py-24 flex flex-col items-center w-full">
    <img src="/assets/imgs/logo_lg.svg" alt="logo" />
    <div
      class="mt-12 w-full max-w-screen md:max-w-[75%] lg:max-w-[600px] mx-auto bg-white rounded-lg overflow-hidden"
    >
      <div class="bg-light-blue2 text-white w-full px-4 md:px-10 pt-5 pb-4">
        <h1 class="text-xl font-bold" transloco="Set Password">
          Set Password
        </h1>
      </div>
      <div class="bg-white px-4 md:px-10 pt-8 pb-[38px] gap-6 flex flex-col">
        <app-form-input
          [label]="t('Passwort')"
          [placeholder]="t('Passwort eingeben') + '...'"
          [fc]="passwordControl"
          [hasError]="passwordControl.invalid && passwordControl.touched"
          [errorMessage]="t('Ungültiges Passwort')"
          inputType="password"
          class="mb-2"
        ></app-form-input>
        <div
          class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 font-medium font-Satoshi"
          role="alert"
          *ngIf="serverErrorMessage"
        >
          {{ translocoService.translate(serverErrorMessage) }}
        </div>
        <div
          class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 font-Satoshi font-medium"
          role="alert"
          *ngIf="success"
          transloco=" Password Changed"
        >
          Password Changed
        </div>
        <div class="font-Satoshi font-medium mb-4">
          <i
            transloco="Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben und ein Sonderzeichen enthalten."
            >Hinweis: Das Passwort muss mindestens 8 Zeichen lang sein, einen
            Großbuchstaben und ein Sonderzeichen enthalten.</i
          >
        </div>
      </div>
      <div class="bg-gray10 px-4 md:px-10 flex justify-end py-[25px]">
        <button
          transloco="SPEICHERN & ANMELDEN"
          class="tw-btn-secondary"
          [disabled]="formIsSubmitting"
          (click)="onSubmit()"
        >
          SPEICHERN & ANMELDEN
        </button>
      </div>
    </div>
  </div>
</div>
