import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RequestViewPageService } from '../request-view-page.service';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { RequestService } from '../../backend-services/request.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SectionRejectionModalService {
  modalEvents: Subject<ModalEvents> = new Subject();

  rejectionCommentControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(255),
  ]);

  constructor(
    private requestViewPageService: RequestViewPageService,
    private authService: AuthService,
    private translocoService: TranslocoService,
    private requestService: RequestService,
    private toastr: ToastrService
  ) {
    this.modalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.modalEvents.next({ type: 'close' });
      }
    });
  }

  rejectCurrentSection() {
    const sectionGroupIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionGroupIndex;
    const sectionIndex =
      this.requestViewPageService.selectedSectionMetaData?.sectionIndex;

    if (
      sectionGroupIndex !== undefined &&
      sectionIndex !== undefined &&
      this.requestViewPageService.requestData &&
      this.requestViewPageService.requestData.section_groups
    ) {
      let title = 'Agency Member';

      const currentSection =
        this.requestViewPageService.requestData.section_groups[
          sectionGroupIndex
        ].sections[sectionIndex];

      if (!!this.requestViewPageService.producerHash) {
        currentSection.status = 302;
        title = this.translocoService.translate(`Producer`);

        this.requestService
          .submitRequestSectionAsProducerV5(
            this.requestViewPageService.requestPublicHash,
            {
              producer_hash: this.requestViewPageService.producerHash,
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [
                {
                  comment: this.rejectionCommentControl.value,
                  title,
                },
              ],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                  this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });
      } else if (this.requestViewPageService.isAgency) {
        currentSection.status = 202;
        title = `${this.authService.profile.value!.first_name} ${
          this.authService.profile.value!.last_name
        }`;

        this.requestService
          .submitRequestSectionAsAgencyV5(
            this.requestViewPageService.requestPublicHash,
            {
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [
                {
                  comment: this.rejectionCommentControl.value,
                  title,
                },
              ],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                  this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });
      } else if(currentSection.status === 106) {
        title = this.translocoService.translate(`Client`);
        currentSection.status = 104;

        this.requestService
          .submitRequestSectionAsClientV5(
            this.requestViewPageService.requestPublicHash,
            {
              id: currentSection.id,
              status: currentSection.status,
              section_comments: [
                {
                  comment: this.rejectionCommentControl.value,
                  title,
                },
              ],
            }
          )
          .subscribe({
            error: (res) => {
              console.log('err', res.error);
              this.toastr.error(
                this.translocoService.translate(res.error?.message || `Something went wrong`)
              );
            },
            next: () => {
              this.toastr.success(this.translocoService.translate('Request updated successfully!'));
              this.requestViewPageService.fetchRequest();
            },
          });

      }


      this.requestViewPageService.handleRequestDataChange();
      this.rejectionCommentControl.setValue('');
    }

    this.modalEvents.next({ type: 'close' });
  }
}
