import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, UserService } from '../../backend-services/user.service';
import {
  Customer,
  CustomerService,
} from '../../backend-services/customer.service';
import { AuthService } from '../../backend-services/auth.service';
import { AdminRoutes } from '../../layouts/admin-layout/admin-routes';
import { EditUserModalService } from '../edit-user-modal/edit-user-modal.service';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { ModalEvents } from '../../shared-components/custom-modal/custom-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

const defaultParams: {
  management_type: string;
} = {
  management_type: 'users',
};

@Component({
  selector: 'app-users-list-view-page',
  templateUrl: './users-list-view-page.component.html',
  styleUrls: ['./users-list-view-page.component.scss'],
})
export class UsersListViewPageComponent {
  queryParams: any = { ...defaultParams };

  public ManageSingleCustomerRoute = AdminRoutes.ManageSingleCustomer;

  deleteConfirmModalEvents = new Subject<ModalEvents>();
  isDeleting = false;
  pendingDeleteItem?: {
    type: 'customer' | 'user';
    id: number;
  } = undefined;

  allUsers: User[] = [];
  allCustomers: Customer[] = [];
  totalUsers = 0;
  totalCustomers = 0;

  searchControl = new FormControl('');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService,
    private editUserModalService: EditUserModalService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      let queryParams: Record<string, string | null> = {};
      params.keys.forEach((k) => {
        queryParams[k] = params.get(k);
      });

      queryParams.management_type =
        queryParams['management_type'] ?? this.queryParams.management_type;

      this.queryParams = queryParams;
      this.fetchData();
    });

    this.authService.profile.subscribe({
      next: () => {
        this.fetchData();
      },
    });

    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((v: any) => {
        this.queryParams.search = v;
        this.handleSearch();
      });

    this.deleteConfirmModalEvents.subscribe((ev) => {
      switch (ev.type) {
        case 'init-close':
          this.deleteConfirmModalEvents.next({ type: 'close' });
          break;
        case 'close':
          this.pendingDeleteItem = undefined;
          this.fetchData();
      }
    });

    this.editUserModalService.modalEvents.subscribe((ev) => {
      if (ev.type === 'close') {
        this.fetchData();
      }
    });
  }

  changeManagementType(newType: string) {
    this.queryParams.management_type = newType;

    this.handleQueryParamChange();
  }

  handleQueryParamChange() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.queryParams,
      queryParamsHandling: 'merge',
    });
  }

  fetchData() {
    // fetch users
    if (this.queryParams.management_type === 'users') {
      this.userService.getUsers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allUsers = res.data;
            this.totalUsers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }

    if (
      this.queryParams.management_type === 'customers' &&
      this.authService.isSuperAdmin.value
    ) {
      // fetch customers if user is SuperAdmin
      this.customerService.getCustomers(this.queryParams).subscribe({
        next: (res) => {
          if (res.success) {
            this.allCustomers = res.data;
            this.totalCustomers = res.total;
          }
        },
        error: (err) => console.log(err),
      });
    }
  }

  openNewUserModal(customer_id?: number) {
    this.editUserModalService.modalEvents.next({ type: 'new', customer_id });
  }

  openEditModal(user: User) {
    this.editUserModalService.modalEvents.next({ type: 'edit', user });
  }

  handleSearch() {
    this.handleQueryParamChange();
    this.fetchData();
  }

  openDeleteConfirmModal(type: 'customer' | 'user', id: number) {
    this.pendingDeleteItem = {
      type,
      id,
    };

    this.deleteConfirmModalEvents.next({ type: 'open' });
  }

  closeDeleteConfirmModal() {
    this.deleteConfirmModalEvents.next({ type: 'close' });
  }

  handleDeleteConfirm() {
    if (this.pendingDeleteItem?.type === 'customer') {
      this.customerService
        .deleteCustomer(this.pendingDeleteItem!.id)
        .subscribe(() => {
          this.toastrService.info(
            this.translocoService.translate('Customer deleted')
          );
          this.closeDeleteConfirmModal();
        });
    } else {
      this.userService.deleteUser(this.pendingDeleteItem!.id).subscribe(() => {
        this.toastrService.info(
          this.translocoService.translate('User deleted')
        );
        this.closeDeleteConfirmModal();
      });
    }
  }
}
