import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../../../../backend-services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { AppService } from '../../../../app.service';
import {License} from "../../../../backend-services/customer.service";

@Component({
  selector: 'app-side-drawer',
  templateUrl: './side-drawer.component.html',
  styleUrls: ['./side-drawer.component.scss'],
})
export class SideDrawerComponent implements AfterViewInit {
  @ViewChild('hideButton') hideButton?: ElementRef;
  @ViewChild('drawerContainer') drawerContainer?: ElementRef;

  public isOpen = false;

  constructor(
    public authService: AuthService,
    private translocoService: TranslocoService,
    public appService: AppService
  ) {}

  ngAfterViewInit(): void {
    const observer = new MutationObserver(() => {
      this.isOpen =
        this.drawerContainer?.nativeElement.getAttribute('role') === 'dialog';
    });

    observer.observe(this.drawerContainer!.nativeElement, { attributes: true });
  }

  get activeLang() {
    return this.translocoService.getActiveLang();
  }

  hideDrawer() {
    this.hideButton?.nativeElement.click();
  }

  logout() {
    this.authService.logout();
    this.hideDrawer();
  }

  get storageDashOffset() {
    const license = this.authService.license.value;
    if (!license) {
      return 1000;
    }

    const percentage = Math.ceil(
      (license.usage.storageUsage / license.usage.availableStorage) * 100
    );

    return 1000 - percentage * 2.9;
  }
}
