<app-custom-modal
  [modalEvents]="sectionApprovalModalService.modalEvents"
  classes=" w-full lg:w-2/3 2xl:w-2/4 max-w-[450px] max-h-full"
  [noBody]="true"
>
  <h3
    header
    class="font-Montserrat text-xl font-bold"
    transloco="Datei wirklich freigeben?"
  >
    Datei wirklich freigeben?
  </h3>
  <div footer class="flex w-full justify-between gap-3">
    <button
      class="tw-btn-secondary-outline"
      (click)="closeModal()"
      transloco="Abbrechen"
    >
      Abbrechen
    </button>
    <button
      class="tw-btn-secondary"
      (click)="sectionApprovalModalService.approveCurrentSection()"
      transloco="Freigeben"
    >
      Freigeben
    </button>
  </div>
</app-custom-modal>
