<div class="relative">
  <input
    type="text"
    [class]="
      'border border-gray3 rounded-lg focus:border-gray15 focus:ring-1 focus:ring-gray15 focus:outline-none p-3 text-sm w-full font-Satoshi text-15px font-medium text-primary' +
      classes
    "
    [attr.type]="
      type === 'password' ? (showPassword ? 'text' : 'password') : type
    "
    [ngClass]="{
      'pr-6': type === 'password',
      'border-red-500 focus:outline-red-500': hasError
    }"
    [attr.placeholder]="placeholder"
    [attr.id]="id"
    [attr.name]="name"
    [attr.disabled]="disabled"
    [attr.maxlength]="maxlength"
    [attr.minlength]="minlength"
    [attr.readonly]="readonly"
    [attr.required]="required"
    [attr.autocomplete]="autocomplete"
    [formControl]="fc"
  />
  <button
    class="absolute right-3 top-1/2 -translate-y-1/2"
    *ngIf="type === 'password'"
    (click)="showPassword = !showPassword"
    type="button"
    tabindex="-1"
  >
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="!showPassword"
    >
      <g clip-path="url(#clip0_215_527)">
        <path
          d="M0.833344 10.4999C0.833344 10.4999 4.16668 3.83325 10 3.83325C15.8333 3.83325 19.1667 10.4999 19.1667 10.4999C19.1667 10.4999 15.8333 17.1666 10 17.1666C4.16668 17.1666 0.833344 10.4999 0.833344 10.4999Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10 12.9999C11.3807 12.9999 12.5 11.8806 12.5 10.4999C12.5 9.11921 11.3807 7.99992 10 7.99992C8.6193 7.99992 7.50001 9.11921 7.50001 10.4999C7.50001 11.8806 8.6193 12.9999 10 12.9999Z"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_215_527">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="showPassword"
    >
      <path
        d="M4.00038 4L20.0004 20M16.5004 16.756C15.1478 17.4846 13.619 18 12.0004 18C8.46973 18 5.36673 15.5479 3.58729 13.7789C3.11759 13.3119 2.88278 13.0785 2.73329 12.6202C2.62668 12.2933 2.62665 11.7064 2.73329 11.3796C2.88282 10.9213 3.11812 10.6874 3.58876 10.2196C4.48564 9.32815 5.7185 8.26353 7.17268 7.4267M19.5004 14.6336C19.8334 14.3407 20.1384 14.0525 20.4122 13.7805C20.8829 13.3126 21.118 13.0787 21.2676 12.6204C21.3742 12.2936 21.3746 11.7067 21.268 11.3799C21.1185 10.9216 20.8835 10.6881 20.4138 10.2211C18.6343 8.45208 15.531 6 12.0004 6C11.6629 6 11.3293 6.02241 11.0004 6.06448M13.3233 13.4999C12.9707 13.8111 12.5076 13.9999 12.0004 13.9999C10.8958 13.9999 10.0004 13.1045 10.0004 11.9999C10.0004 11.4604 10.214 10.9708 10.5613 10.6111"
        stroke="#667085"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</div>
