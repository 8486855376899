<nav class="z-20 w-full bg-primary px-4">
  <div
    class="container flex flex-wrap items-center justify-between mx-auto py-3"
  >
    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
      <img src="assets/imgs/ahoidata_white.svg" class="h-16" alt="AHOIDATA Logo" />
    </a>
    <button
      data-collapse-toggle="navbar-solid-bg"
      type="button"
      class="inline-flex items-center p-2 w-10 h-10 justify-center text-xs 2xl:text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      aria-controls="navbar-solid-bg"
      aria-expanded="false"
    >
      <span class="sr-only" transloco="Open main menu">Open main menu</span>
      <svg
        class="w-5 h-5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 17 14"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 1h15M1 7h15M1 13h15"
        />
      </svg>
    </button>
    <div class="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
      <ul
        class="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700"
      >
        <li *ngIf="false">
          <div
            class="block py-2 px-3 md:p-0 text-white font-bold rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-400 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
            transloco="Hilfe"
          >Hilfe?</div
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
