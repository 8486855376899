import { Component } from '@angular/core';
import { FileDeleteConfirmationModalService } from './file-delete-confirmation-modal.service';

@Component({
  selector: 'app-file-delete-confirmation-modal',
  templateUrl: './file-delete-confirmation-modal.component.html',
  styleUrls: ['./file-delete-confirmation-modal.component.scss'],
})
export class FileDeleteConfirmationModalComponent {
  constructor(
    public fileDeleteConfirmationModalService: FileDeleteConfirmationModalService
  ) {}

  closeModal() {
    this.fileDeleteConfirmationModalService.modalEvents.next({ type: 'close' });
  }
}
