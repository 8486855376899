<div class="flex flex-col font-Montserrat" *ngIf="cachedSelectedSection">
  <div
    class="font-semibold text-sm leading-22.4px tracking-0.6px text-primary opacity-70 uppercase"
  >
    {{ selectedUploadedFile?.title }}
  </div>
  <div class="font-normal text-sm leading-22.4px text-primary opacity-70" *ngIf="selectedUploadedFile?.file_size">
    {{ selectedUploadedFile?.file_size | fileSize }}
  </div>
  <div
    class="w-full border border-gray7 rounded-xl p-6 mt-4 relative min-h-[280px]"
  >
    <div
      class="slick-carousel w-full h-full overflow-hidden duration-500 flex justify-center items-center"
    >
      <div class="" *ngFor="let file of cachedSelectedSection?.uploaded_files">
        <div class="flex w-full h-full justify-center items-center">
          <img
            [src]="sanitizer.bypassSecurityTrustResourceUrl(file.preview_url)"
            alt=""
            class="object-contain max-h-[350px]"
          />
        </div>
      </div>
    </div>

    <div class="absolute bottom-3 left-0 right-0">
      <div class="relative px-3 w-full min-h-[50px]">
        <div
          class="bg-white rounded-xl text-primary flex gap-3 items-center self-start w-[135px] min-h-[40px] absolute bottom-0 left-3"
          *ngIf="showNavigator"
        >
          <button class="h-8 w-8 p-1 slick-prev-arrow">
            <img
              src="/assets/imgs/icons/bs-chevron-left.svg"
              alt=""
              class="w-6 h-6"
            />
          </button>
          <div>{{ requestViewPageService.selectedUploadFileIndex + 1 }}</div>
          <div>/</div>
          <div>{{ fileCount }}</div>
          <button class="h-8 w-8 p-1 slick-next-arrow">
            <img
              src="/assets/imgs/icons/bs-chevron-left.svg"
              alt=""
              class="w-6 h-6 rotate-180"
            />
          </button>
        </div>
        <div
          class="bg-white px-7 py-3 rounded-xl border border-light-blue3 w-fit text-light-blue3 font-bold text-xs leading-18px min-h-[40px] absolute bottom-0 left-1/2 -translate-x-1/2"
          transloco="Vorschau"
        >
          Vorschau
        </div>
        <div
          class="flex justify-end w-[135px] min-h-[40px] absolute right-3 bottom-0"
        >
          <button
            class="rounded-lg p-3 border border-gray12 w-fit bg-white"
            (click)="removeFile()"
          >
            <img src="assets/imgs/icons/trash.svg" alt="trash" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
